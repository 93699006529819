import { mountStoreDevtool } from 'simple-zustand-devtools';

import { isBrowser } from 'u9/utils/platform';
import { getUrlParams } from 'u9/utils/url';

import useCopyStore from './copy';
import useGlobalStore from './global';
import useOpportunitiesStore from './opportunities';

function addStoreDevtool(name: string, store) {
  const separateRoot = document.createElement('div');
  separateRoot.id = `devtools-${name}`;
  document.body.appendChild(separateRoot);
  mountStoreDevtool(name, store, separateRoot);
}

const setupStoreDevTools = () => {
  if (
    isBrowser() &&
    (process.env.IS_DEBUG || getUrlParams().get('debugStore'))
  ) {
    addStoreDevtool('CopyStore', useCopyStore);
    addStoreDevtool('GlobalStore', useGlobalStore);
    addStoreDevtool('OpportunitiesStore', useOpportunitiesStore);
  }
};

export {
  setupStoreDevTools,
  useCopyStore,
  useGlobalStore,
  useOpportunitiesStore,
};

import { debounce, uniqueId } from 'lodash';
import Link from 'next/link';
import React, { useCallback, useState } from 'react';

import useSearch from 'hooks/useSearch';
import { redirectToPageUrl } from 'utils';
import { GlobalSearchResultsTypes } from 'utils/sharedStrapiQueries/sharedTypes';
import { colors } from 'utils/styles/theme';

import * as S from './Search.styles';

export interface SearchProps {
  placeholder: string;
  onCancel: () => void;
}

const defaultProps: Partial<SearchProps> = {
  placeholder: 'Search',
};

const SearchResult: React.FunctionComponent<GlobalSearchResultsTypes> = ({
  heading,
  description,
  tag,
  caseStudy,
  redirectTo,
  ...pages
}) => {
  const [isHovered, setIsHovered] = useState(false);
  // @ts-ignore
  const url = redirectToPageUrl(redirectTo, { caseStudy, ...pages });
  return (
    <Link href={url} passHref>
      <S.Result
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <S.Tag text={tag?.tag} size="smallBody" />
        <S.Title text={heading} size="heading4" hovered={isHovered} />
        <S.Description text={description} size="smallBody" />
      </S.Result>
    </Link>
  );
};

const Search: React.FunctionComponent<SearchProps> = ({
  placeholder,
  onCancel,
}) => {
  const { results, searchFor, isLoading } = useSearch();

  const debounceSearch = useCallback(
    debounce(e => searchFor(e.target.value), 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchFor]
  );

  const listResults = results.map(r => (
    <SearchResult {...r} key={uniqueId()} />
  ));

  return (
    <S.SearchWrapper>
      <S.SearchTopFlex>
        <S.Input
          type="text"
          placeholder={placeholder}
          onChange={debounceSearch}
          autoComplete="off"
          autoFocus
        />

        <S.CancelButton onClick={() => onCancel()}>
          <S.CancelIcon />
        </S.CancelButton>
      </S.SearchTopFlex>

      {isLoading ? (
        <S.Loader color={colors.brightGray} />
      ) : (
        <S.Results>{listResults}</S.Results>
      )}
    </S.SearchWrapper>
  );
};

Search.defaultProps = defaultProps;

export default Search;

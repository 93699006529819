import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { InsightsPageDataCMS } from 'containers/InsightsPage/InsightsPage.types';
import {
  WorkPageDataCMS,
  WorkProjectsTypes,
} from 'containers/WorkPage/WorkPage.types';
import { useGlobalStore } from 'store';
import { InsightsArticlesTypes } from 'types/shared';
import { getSearchDataCMS, mappedData } from 'utils/getSearchData';
import {
  AdditionalSearchDataTypes,
  GlobalSearchResultsTypes,
  SearchDataTypes,
} from 'utils/sharedStrapiQueries/sharedTypes';

type IndexedDataTypes = (WorkProjectsTypes | InsightsArticlesTypes) & {
  writingDate: string | Date;
} & AdditionalSearchDataTypes;

const searchParse = (string: string, searchValue: string) =>
  string.toLowerCase().includes(searchValue.toString().toLowerCase());

const caseStudiesIndexedData = (item: IndexedDataTypes, value: string) => {
  if (!item.caseStudy) return false;

  const {
    caseStudy: {
      introPanel: { keyPair, caseStudyOverlay },
    },
  } = item;

  const caseStudyOverlayItems = Object.values(caseStudyOverlay).map(item =>
    searchParse(item, value)
  );
  const keyPairItems = keyPair.map(item => searchParse(item.field, value));

  return [...caseStudyOverlayItems, ...keyPairItems].find(Boolean);
};

const articlesIndexedData = (item: IndexedDataTypes, value: string) => {
  const modules =
    item.awardPage?.modules ||
    item.eventPage?.modules ||
    item.newsArticle?.modules ||
    item.insightArticle?.modules;

  if (modules) {
    const mod = modules.map((module: any) => {
      const typename = module.__typename;

      if (typename === 'ComponentComponentsQuote') {
        const { quote, author, description } = module;
        return (
          searchParse(quote, value) ||
          searchParse(author, value) ||
          searchParse(description, value)
        );
      }
      if (typename === 'ComponentComponentsRichText') {
        const { richText } = module;
        return searchParse(richText, value);
      }
      return false;
    });

    return mod.find(Boolean);
  }

  return false;
};

export const formatData = (searchData: {
  workPage: WorkPageDataCMS;
  insightsPage: InsightsPageDataCMS;
}) => {
  const { workPage, insightsPage } = searchData;

  const workData = workPage.projects;
  const insightsData = insightsPage.articles;

  const mappedWork = mappedData(
    workData as SearchDataTypes['workPage']['projects']
  );
  const mappedInsights = mappedData(
    insightsData as unknown as SearchDataTypes['insightsPage']['articles']
  );

  const sortInsightsData = mappedInsights.sort(
    (a, b) =>
      Date.parse(b.writingDate as string) - Date.parse(a.writingDate as string)
  );
  return [...mappedWork, ...sortInsightsData];
};

const searchForParse = (data: GlobalSearchResultsTypes[], value) =>
  data.filter(
    item =>
      searchParse(item.heading, value) ||
      searchParse(item.description, value) ||
      caseStudiesIndexedData(item, value) ||
      articlesIndexedData(item, value)
  );

const useSearch = () => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const { searchResults, setSearchResults } = useGlobalStore();

  const [results, setResults] = useState<GlobalSearchResultsTypes[]>([]);
  const [parse, searchFor] = useState<string>('');

  const getData = async () => {
    setIsLoading(true);

    const data = await getSearchDataCMS(router.locale);
    setSearchResults(formatData(data));

    setIsLoading(false);
  };

  useEffectOnce(() => {
    getData().then();
  });

  useEffect(() => {
    const indexedData = searchForParse(searchResults, parse);

    setResults(indexedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parse, isLoading]);

  return { isLoading, results, searchFor };
};

export default useSearch;

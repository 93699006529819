import { AnimatePresence } from 'framer-motion';
import React, { FunctionComponent } from 'react';

import LinkComp from 'components/LinkComp/LinkComp';
import Toast from 'components/Toast/Toast';
import useClipboard from 'hooks/useClipboard';
import share from 'u9/utils/share';

import * as S from './SocialsPanel.styles';

export type TypesOfSocials = 'facebook' | 'twitter' | 'linkedin';

export type SocialsTypes = {
  text?: string;
  socials?: Array<{ title: string; type: TypesOfSocials }>;
  links?: Array<{ title: string; url?: string }>;
  toastText: string;
};

export interface SocialsPanelProps {
  socials: SocialsTypes;
  shareText?: string;
}

const defaultProps: Partial<SocialsPanelProps> = {
  socials: {
    text: '',
    socials: [],
    links: [],
    toastText: '',
  },
  shareText: '',
};

const SocialsPanel: FunctionComponent<SocialsPanelProps> = ({
  socials,
  shareText,
  ...rest
}) => {
  const { isCopied, copyToClipboard } = useClipboard();

  const shareAction = async (
    link: string,
    text = '',
    type: TypesOfSocials,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    summary?: string
  ) => {
    const typeOfSocials = {
      facebook: () => share.facebook(link),
      twitter: () => share.twitter(link, text),
      linkedin: () => share.linkedin(link, text, (summary = '')),
    };
    try {
      await typeOfSocials[type]();
    } catch (err) {
      // TODO: Error message if needed
    }
  };

  const socialsComp = (socials?.socials || []).map(({ title, type }, index) => (
    <S.SocialContainer
      key={title + index}
      aria-label={title}
      onClick={() => shareAction(window.location.href, shareText, type, title)}
    >
      <LinkComp href={undefined} isExternal>
        {title}
      </LinkComp>
    </S.SocialContainer>
  ));

  const socialsLinks = (socials?.links || []).map(({ title, url }, index) => (
    <S.SocialContainer
      key={title + index}
      aria-label={title}
      onClick={() => !url && copyToClipboard(window.location.href)}
    >
      <LinkComp href={url || undefined} isExternal>
        {title}
      </LinkComp>
    </S.SocialContainer>
  ));

  return (
    <S.Wrapper {...rest}>
      {socialsComp}
      {socialsLinks}

      <AnimatePresence>
        {isCopied && <Toast text={socials?.toastText || '✓'} />}
      </AnimatePresence>
    </S.Wrapper>
  );
};

SocialsPanel.defaultProps = defaultProps;

export default SocialsPanel;

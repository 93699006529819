import styled from 'styled-components';

import Text from 'components/Text/Text';
import { ReactComponent as CANCEL } from 'svgs/cancel.svg';
import { setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

import LoadingDots from '../LoadingDots/LoadingDots';

export const SearchWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const SearchTopFlex = styled.div`
  width: 100%;
  height: 100%;

  margin: 0 0 10% 0;

  display: inline-flex;
  justify-content: space-between;
`;

export const Input = styled.input`
  width: 100%;
  ${({ theme: { locale } }) => setTypography('heading1', locale)};
`;

export const Result = styled.li`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 28px 30px 28px 0;

  border-top: 2px solid ${colors.white};
`;

export const Results = styled.ul`
  display: flex;
  flex-direction: column;

  width: 100%;

  ${mediaDesktop(`
    width: 60%;
  `)}
`;

export const Tag = styled(Text)`
  margin-bottom: 8px;
`;

export const CancelIcon = styled(CANCEL)`
  color: ${colors.white};

  width: 16px;
  height: 16px;
`;

export const CancelButton = styled.button`
  padding: 20px;
  border-radius: 33px;

  align-self: center;

  background: ${colors.codGray};
`;

export interface TitleProps {
  hovered?: boolean;
}
export const Title = styled(Text)<TitleProps>`
  ${({ hovered }) => hovered && 'text-decoration: underline'};
`;

export const Description = styled(Text)`
  color: ${colors.dustyGray};
`;

export const Loader = styled(LoadingDots)`
  transform: scale(2);
  margin: auto;
`;

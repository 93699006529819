import lottie, { AnimationItem } from 'lottie-web';
import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import { animationData, AnimationsKeys } from './AnimatedLogo.utils';

import * as S from './AnimatedLogo.styles';

export interface AnimatedLogoProps {
  animationName: AnimationsKeys;
  invertColor?: boolean;
}

export interface AnimatedLogoRef {
  showLayers: () => void;
  hideLayers: () => void;
}

const AnimatedLogo = forwardRef<AnimatedLogoRef, AnimatedLogoProps>(
  ({ animationName, invertColor = false, ...rest }, ref) => {
    const lottieRef = useRef<HTMLDivElement>(null);
    const animationRef = useRef<AnimationItem | null>(null);

    useEffect(() => {
      const anim = lottie.loadAnimation({
        container: lottieRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData[animationName] ?? animationData['about'],
      });

      animationRef.current = anim;

      return () => anim.destroy();
    }, []);

    const hideLayers = () => {
      const svgElements = lottieRef?.current?.querySelectorAll('svg g');
      handleMouseEnter();
      svgElements?.forEach((layer: any, index) => {
        if ((index >= 61 && index <= 169) || index === 12) {
          if (+layer.style.opacity === 1 || layer.style.opacity === '') {
            layer.style.transition = 'all 0.3s linear';
            layer.style.opacity = '0';
          }
        }
      });
    };

    const showLayers = () => {
      const svgElements = lottieRef?.current?.querySelectorAll('svg g');
      svgElements?.forEach((layer: any, index) => {
        if ((index >= 61 && index <= 169) || index === 12) {
          if (+layer.style.opacity === 0) layer.style.opacity = '1';
        }
      });

      setTimeout(() => animationRef.current.play(), 500);
    };

    useImperativeHandle(ref, () => ({
      showLayers,
      hideLayers,
    }));

    const handleMouseEnter = () => {
      if (animationRef.current) {
        animationRef.current.goToAndPlay(10, true);
        setTimeout(() => animationRef.current.pause(), 200);
      }
    };

    const handleMouseLeave = () => {
      if (animationRef.current) {
        animationRef.current.play();
      }
    };

    return (
      <S.Wrapper
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0.5 }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...rest}
      >
        <S.LottieWrapper invertColor={invertColor} ref={lottieRef} />
      </S.Wrapper>
    );
  }
);

AnimatedLogo.displayName = 'AnimatedLogo';

export default memo(AnimatedLogo);

import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';

import * as S from './WeChatQrCode.styles';

export interface WeChatQrCodeProps {
  option: 1 | 0;
}

const defaultProps: Partial<WeChatQrCodeProps> = {
  option: 0,
};

const WeChatQrCode: FunctionComponent<WeChatQrCodeProps> = ({
  option,
  ...rest
}) => {
  const { locale } = useRouter();
  const images = {
    0: {
      src: '/images/wechat-QRcode-transparent.png',
      alt: 'Wechat-QRcode transparent',
    },
    1: {
      src: '/images/wechat-QRcode-inverted.jpg',
      alt: 'Wechat-QRcode inverted',
    },
  };

  if (!locale.includes('zh-CN')) return null;

  return (
    <S.QrCodeImage
      src={images[option].src}
      alt={images[option].alt}
      isMargin={option === 0}
      {...rest}
    />
  );
};

WeChatQrCode.defaultProps = defaultProps;

export default WeChatQrCode;

import { AnimatePresence } from 'framer-motion';
import Cookies from 'js-cookie';
import React, { FunctionComponent, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import Button from 'components/Button/Button';
import RichText from 'components/RichText/RichText';
import { useGlobalStore } from 'store';
import { opacityMotionProps } from 'utils/styles/animations';

import * as S from './CookieBar.styles';

export type CookieBarTypes = {
  buttonTextAccept: string;
  buttonTextReject: string;
  necessaryCookieLabel: string;
  functionalityCookieLabel: string;
  analyticsCookieLabel: string;
  mainCopy: string;
};

export interface CookieBarProps {
  cookieBarData: CookieBarTypes;
}

const defaultProps: Partial<CookieBarProps> = {
  cookieBarData: {
    buttonTextAccept: '',
    buttonTextReject: '',
    necessaryCookieLabel: '',
    functionalityCookieLabel: '',
    analyticsCookieLabel: '',
    mainCopy: '',
  },
};

const CookieBar: FunctionComponent<CookieBarProps> = ({
  cookieBarData,
  ...rest
}) => {
  const { buttonTextAccept, buttonTextReject, mainCopy } = cookieBarData;

  const [isVisible, setIsVisible] = useState(false);

  const { setCookiesConfirmation } = useGlobalStore();

  const checkCookies = () => {
    const cookies = Cookies.get('dbp.cookiesConsent');
    setIsVisible(!cookies);
  };

  const acceptCookies = () => {
    Cookies.set('dbp.cookiesConsent', 'true', { expires: 365 });
    Cookies.set('dbp.functionality', 'true', {
      expires: 365,
    });
    Cookies.set('dbp.analytics', 'true', { expires: 365 });
    setCookiesConfirmation(true);
    setIsVisible(false);
  };

  const rejectCookies = () => {
    Cookies.set('dbp.cookiesConsent', 'false', { expires: 365 });
    Cookies.set('dbp.functionality', 'false', {
      expires: 365,
    });
    Cookies.set('dbp.analytics', 'false', { expires: 365 });
    setCookiesConfirmation(true);
    setIsVisible(false);
  };

  useEffectOnce(checkCookies);

  return (
    <AnimatePresence initial>
      {isVisible && (
        <S.CookieBarWrapper {...opacityMotionProps} {...rest}>
          <S.CookieBarGrid>
            <S.TextWrapper>
              <S.InfoWrapper>
                <RichText
                  as="p"
                  size="small"
                  richText={mainCopy}
                  defaultFormatting
                />
              </S.InfoWrapper>
            </S.TextWrapper>

            <S.BottomContentWrapper>
              <S.ButtonWrapper>
                <Button label={buttonTextAccept} onClick={acceptCookies} />
              </S.ButtonWrapper>

              {buttonTextReject?.length && (
                <S.ButtonWrapper>
                  <Button label={buttonTextReject} onClick={rejectCookies} />
                </S.ButtonWrapper>
              )}
            </S.BottomContentWrapper>
          </S.CookieBarGrid>
        </S.CookieBarWrapper>
      )}
    </AnimatePresence>
  );
};

CookieBar.defaultProps = defaultProps;

export default CookieBar;

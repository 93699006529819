import React, { FunctionComponent, memo } from 'react';

import Text from 'components/Text/Text';
import { useCopyStore, useGlobalStore } from 'store';
import { handleMouseEnter, handleMouseLeave } from 'utils/cursorGlobal';
import { opacityMotionProps } from 'utils/styles/animations';
import { ColorNames } from 'utils/styles/theme';

import * as S from './Header.styles';

export interface MenuWrapperProps {
  children?: React.ReactNode;
  conditions?: { colorCondition: ColorNames };
  disableOnDesktop?: boolean;
}

const defaultProps: Partial<MenuWrapperProps> = {};

const MenuWrapper: FunctionComponent<MenuWrapperProps> = ({
  children,
  conditions,
  disableOnDesktop,
  ...rest
}) => {
  const { copy } = useCopyStore();

  const { isMenuOpen, setIsMenuOpen } = useGlobalStore();

  const menuLabelText = isMenuOpen
    ? copy.navbar?.hamburgerLabelClose || 'Close'
    : copy.navbar?.hamburgerLabelOpen || 'Menu';

  return (
    <S.MenuWrapper
      key={`${menuLabelText}`}
      tabIndex={0}
      whileHover={{
        opacity: 0.8,
      }}
      textHoveringColor={
        !isMenuOpen &&
        (copy.navbar?.linksHoverColor || copy.navbar?.linksHoverDefaultColors)
      }
      whileTap={{ scale: 1.01 }}
      {...opacityMotionProps({ duration: 0.4 })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      disableOnDesktop={disableOnDesktop}
      {...rest}
    >
      {children}
      <Text
        as="h4"
        size="heading4"
        text={menuLabelText}
        color={conditions.colorCondition}
        onClick={() => (setIsMenuOpen(!isMenuOpen), handleMouseLeave())}
      />
    </S.MenuWrapper>
  );
};

MenuWrapper.defaultProps = defaultProps;

export default memo(MenuWrapper);

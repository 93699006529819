import styled from 'styled-components';

import { colors } from 'utils/styles/theme';

interface WrapperProps {
  transparent?: boolean;
  hoverColor?: string;
}

export const Wrapper = styled.button<WrapperProps>`
  display: flex;
  align-items: flex-start;

  padding: 12px 20px;
  border-radius: 76px;

  ${({ transparent }) =>
    transparent
      ? `
      background: rgba(242, 242, 242, 0.2);
      backdrop-filter: blur(5px);
    `
      : `
      background: ${colors.codGrayLight};
  `};

  transition: background-color 0.3s ease;

  &:hover {
    ${({ transparent, hoverColor }) =>
      transparent
        ? `
      background: ${colors.concrete};
      backdrop-filter: blur(5px);

      span {
        color: ${
          (colors[hoverColor] ?? hoverColor) || colors.lighterMineShaft
        } !important;
      }
    `
        : `
      background: ${colors.codGrayHover};
      ${
        hoverColor &&
        `
          span {
            color: ${colors[hoverColor] ?? hoverColor} !important;
          }
        `
      }
  `};
  }
`;

export const Anchor = styled.a`
  width: fit-content;
  grid-column: 1/-1;
`;

export default `
  @font-face {
    font-family: 'Sequel Sans';
    font-weight: 70;
    font-style: normal;
    font-display: block;
    src: url('/fonts/SequelSansForDesign/SequelSansforDesign-Light.woff2') format('woff2'),
      url('/fonts/SequelSansForDesign/SequelSansforDesign-Light.woff') format('woff');
  }

  @font-face {
    font-family: 'Sequel Sans';
    font-weight: 135;
    font-style: normal;
    font-display: block;
    src: url('/fonts/SequelSansForDesign/SequelSansforDesign-Bold.woff2') format('woff2'),
      url('/fonts/SequelSansForDesign/SequelSansforDesign-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Sequel Sans';
    font-weight: 110;
    font-style: normal;
    font-display: block;
    src: url('/fonts/SequelSansForDesign/SequelSansforDesign-Regular.woff2') format('woff2'),
      url('/fonts/SequelSansForDesign/SequelSansforDesign-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Sequel Sans';
    font-weight: 150;
    font-style: normal;
    font-display: block;
    src: url('/fonts/SequelSansForDesign/SequelSansforDesign-Hdline.woff2') format('woff2'),
      url('/fonts/SequelSansForDesign/SequelSansforDesign-Hdline.woff') format('woff');
  }

  @font-face {
    font-family: 'Sequel Sans';
    font-weight: 135;
    font-style: italic;
    font-display: block;
    src: url('/fonts/SequelSansForDesign/SequelSansforDesign-Italic.woff2') format('woff2'),
      url('/fonts/SequelSansForDesign/SequelSansforDesign-Italic.woff') format('woff');
  }

  @font-face {
    font-family: 'Sequel Sans VFB1';
    font-weight: 110;
    font-style: normal;
    font-display: block;
    src: url('/fonts/SequelSans/SequelSansVFC1Headline_normal_normal.woff2') format('woff2'),
      url('/fonts/SequelSans/SequelSansVFC1Headline_normal_normal.woff') format('woff');
  }

  @font-face {
    font-family: 'Sequel Sans VFC1';
    font-weight: 135;
    font-style: normal;
    font-display: block;
    src: url('/fonts/SequelSans/SequelSansVFC1Headline_normal_normal.woff2') format('woff2'),
      url('/fonts/SequelSans/SequelSansVFC1Headline_normal_normal.woff') format('woff');
  }

  // NotoSans

  @font-face {
    font-family: 'Noto Sans SC';
    font-weight: 70;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Noto_Sans_SC/NotoSansSC-Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'Noto Sans SC';
    font-weight: 135;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Noto_Sans_SC/NotoSansSC-Bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Noto Sans SC';
    font-weight: 110;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Noto_Sans_SC/NotoSansSC-Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'Noto Sans SC';
    font-weight: 150;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Noto_Sans_SC/NotoSansSC-Black.otf') format('opentype');
  }

  // OppoSans

  @font-face {
    font-family: 'OPPO Sans';
    font-weight: 70;
    font-style: normal;
    font-display: block;
    src: url('/fonts/opposans/OPPOSans-L/OPPOSans-L-2.ttf') format('truetype');
  }

  @font-face {
    font-family: 'OPPO Sans';
    font-weight: 110;
    font-style: normal;
    font-display: block;
    src: url('/fonts/opposans/OPPOSans-R/OPPOSans-R-2.ttf') format('truetype');
  }

  @font-face {
    font-family: 'OPPO Sans';
    font-weight: 135;
    font-style: normal;
    font-display: block;
    src: url('/fonts/opposans/OPPOSans-B/OPPOSans-B-2.ttf') format('truetype');
  }

  @font-face {
    font-family: 'OPPO Sans';
    font-weight: 150;
    font-style: normal;
    font-display: block;
    src: url('/fonts/opposans/OPPOSans-H/OPPOSans-H-2.ttf') format('truetype');
  }
`;

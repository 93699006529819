import React, { FunctionComponent, useCallback } from 'react';

import { useGlobalStore } from 'store';

import * as S from './PageTransition.styles';

export interface PageTransitionProps {
  children: React.ReactNode;
}

const defaultProps: Partial<PageTransitionProps> = {
  children: <p>Example</p>,
};

const PageTransition: FunctionComponent<PageTransitionProps> = ({
  children,
}) => {
  const { setIsNavRedirecting } = useGlobalStore();

  const onAnimationStart = useCallback(animation => {
    if (animation === 'exit') setIsNavRedirecting(true);
    else if (animation === 'animate') setIsNavRedirecting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const variants = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { delay: 1.4, duration: 1, ease: [0.25, 1, 0.25, 1] },
    },
    exit: {
      opacity: 0,
      transition: { delay: 0, duration: 0.4, ease: [0.25, 1, 0.25, 1] },
    },
  };

  return (
    <>
      <S.FadeInOut
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.4, ease: [0.25, 1, 0.25, 1] }}
        onAnimationStart={onAnimationStart}
      >
        {children}
      </S.FadeInOut>
    </>
  );
};

PageTransition.defaultProps = defaultProps;

export default PageTransition;

import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

import { MAX_DOT_SIZE } from './Cursor.utils';

interface LabelProps {
  isWhite: boolean;
}

interface PointerProps {
  variants: any;
  animate: any;
  dragCursor?: boolean;
}

const dragCursorIconStyles = css`
  content: '';
  width: 14px;
  height: 10px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  color: ${colors.white};
`;

export const CursorWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  pointer-events: none;
  transform-origin: center;
`;

export const Pointer = styled(motion.div)<PointerProps>`
  display: grid;
  place-items: center;
  position: relative;
  width: ${MAX_DOT_SIZE}px;
  height: ${MAX_DOT_SIZE}px;
  background-color: ${colors.black};
  border-radius: 50%;
  image-rendering: smooth;

  ${({ dragCursor }) =>
    dragCursor &&
    css`
      &::after {
        ${dragCursorIconStyles}
        bottom: 17.7px;
        background: url('/svgs/downArrow.svg') no-repeat center center;
      }

      &::before {
        ${dragCursorIconStyles}
        top: 17.7px;
        background: url('/svgs/upArrow.svg') no-repeat center center;
      }
    `}
`;

export const Label = styled(motion.span)<LabelProps>`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 40px;
  ${({ theme: { locale } }) => setTypography('small', locale)};
  text-align: center;
  color: ${({ isWhite }) => (isWhite ? colors.white : colors.black)};
`;

import { gql } from 'apollo-boost';

import { InsightsPageDataCMS } from 'containers/InsightsPage/InsightsPage.types';
import { WorkPageDataCMS } from 'containers/WorkPage/WorkPage.types';
import { cmsApiClient } from 'utils/cms/api';
import {
  getEffectiveLocale,
  getMarket,
  getMarketPageId,
  MarketPages,
} from 'utils/sharedStrapiQueries/market';
import {
  GlobalSearchResultsTypes,
  SearchDataTypes,
} from 'utils/sharedStrapiQueries/sharedTypes';

export const getSearchDataCMS = async (locale: string) => {
  const insights = await getMarket(MarketPages.insightsPage);
  const work = await getMarket(MarketPages.workPage);

  const market = { ...insights, ...work };
  const effectiveLocale = await getEffectiveLocale(market, locale);

  const workPageId = getMarketPageId(market.workPage, effectiveLocale);
  const insightsPageId = getMarketPageId(market.insightsPage, effectiveLocale);

  const pagesQuery = `
    newsArticle {
      slug
      writingDate
      modules {
        ... on ComponentComponentsQuote {
          quote
          author
          description
          variant
        }
        ...on ComponentComponentsRichText {
          richText
        }
      }
    }
    caseStudy {
      slug
      writingDate
      introPanel {
        keyPair {
          field
        }
        caseStudyOverlay {
          sectionTitle
          heading
          richText
        }
      }
      basicAuthEnabled
    }
    awardPage {
      slug
      writingDate
      modules {
        ...on ComponentComponentsRichText {
          richText
        }
      }
    }
    eventPage {
      slug
      writingDate
      modules {
        ...on ComponentComponentsRichText {
          richText
        }
      }
    }
    insightArticle {
      slug
      writingDate
      modules {
        ... on ComponentComponentsQuote {
          quote
          author
          description
          variant
        }
        ...on ComponentComponentsRichText {
          richText
        }
      }
    }
`;

  return cmsApiClient
    .query({
      query: gql`
        {
          workPage(id: ${workPageId}) {
            projects {
              id
              heading
              description
              tag {
                tag
              }
              redirectTo
              ${pagesQuery}
            }
          }

          insightsPage(id: ${insightsPageId}) {
            articles {
              id
              heading
              description
              tag {
                tag
              }
              redirectTo
              ${pagesQuery}
            }
          }
        }
      `,
    })
    .then(response => {
      const { projects } = response.data.workPage;
      const { articles } = response.data.insightsPage;

      const noAuthProjects = projects.filter(
        (data: any) => !data?.caseStudy?.basicAuthEnabled
      );
      const noAuthArticles = articles.filter(
        (data: any) => !data?.caseStudy?.basicAuthEnabled
      );

      const mergedWorkPageData = {
        ...response.data.workPage,
        projects: noAuthProjects,
      };

      const mergedInsightsPageData = {
        ...response.data.insightsPage,
        articles: noAuthArticles,
      };

      return {
        workPage: mergedWorkPageData as WorkPageDataCMS,
        insightsPage: mergedInsightsPageData as InsightsPageDataCMS,
      };
    });
};

export const parseWritingDate = (data: {
  [key: string]: any | { writingDate: string };
}) => {
  const writingDate =
    data.caseStudy?.writingDate ||
    data.awardPage?.writingDate ||
    data.eventPage?.writingDate ||
    data.newsArticle?.writingDate ||
    data.insightArticle?.writingDate ||
    '';

  const replacedDateString = writingDate.replace(/-/g, '/');

  return new Date(replacedDateString);
};

export const mappedData = (
  data:
    | SearchDataTypes['workPage']['projects']
    | SearchDataTypes['insightsPage']['articles']
): Array<GlobalSearchResultsTypes> =>
  data.map(item => ({
    ...item,
    writingDate: parseWritingDate(item),
  }));

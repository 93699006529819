import styled from 'styled-components';

interface QrCodeImageProps {
  isMargin: boolean;
}

export const QrCodeImage = styled.img<QrCodeImageProps>`
  max-width: 106px;
  max-height: 106px;

  ${({ isMargin }) => isMargin && 'margin-left: 55px'};
`;

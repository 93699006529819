import { AnimatePresence, useAnimation } from 'framer-motion';
import uniqueId from 'lodash/uniqueId';
import router, { useRouter } from 'next/router';
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import LinkComp from 'components/LinkComp/LinkComp';
import Text from 'components/Text/Text';
import { useCopyStore, useGlobalStore } from 'store';
import { DEFAULT_HEADER_TEXT } from 'store/global';
import { ReactComponent as SvgGlobe } from 'svgs/globe.svg';
import { ReactComponent as SvgSearch } from 'svgs/search_icon.svg';
import { isMobile } from 'u9/utils/platform';
import { calculateScrollPercentage } from 'utils';
import { handleLanguage, languages } from 'utils/cms/languages';
import { Pages, ROUTES } from 'utils/routes';
import { NavLinkCMS } from 'utils/sharedStrapiQueries/sharedTypes';
import { ColorNames, colors } from 'utils/styles/theme';
import { breakpointDesktop } from 'utils/styles/vars';

import AnimatedLogo, { AnimatedLogoRef } from './AnimatedLogo/AnimatedLogo';
import {
  ANIMATED_LOGO_PAGES,
  DISABLED_HEADER_LOGO,
  LIGHT_HEADER,
} from './Header.constants';
import { animation } from './Header.utils';
import MenuWrapper from './MenuWrapper';

import * as S from './Header.styles';

export interface HeaderProps {}

const defaultProps: Partial<HeaderProps> = {};

const Header: FunctionComponent<HeaderProps> = ({ ...rest }) => {
  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] =
    useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const animatedLogoRef = useRef<AnimatedLogoRef>(null);
  const globeRef = useRef<HTMLDivElement>(null);

  const { copy } = useCopyStore();

  const {
    headerText,
    isCopyLogo,
    isGridView,
    isMenuOpen,
    isNavRedirecting,
    isHeaderTextVisible,
    isEnteringWorkPageContent,
    isSearchOverlayOpen,
    setIsMenuOpen,
    setIsCopyLogo,
    setIsSearchOverlayOpen,
    setHeaderText,
  } = useGlobalStore();

  const { route, push, pathname, locale } = useRouter();
  const animate = useAnimation();

  useOnClickOutside(
    globeRef,
    () => pathname === ROUTES.HOME && setIsLanguageSelectorOpen(false)
  );

  const navLinks = copy.navbar?.homePageNavLinks || [];
  const contactLinks = navLinks.filter(link =>
    link?.href.includes(ROUTES.CONTACT)
  );
  const isBackgroundFromCMS = Boolean(
    copy.navbar?.menuBackgroundColor || copy.navbar?.menuBackgroundDefaultColors
  );

  useEffect(() => {
    // Part of page transition animation
    if (isNavRedirecting) animation(animate, 0), setIsMenuOpen(false);
    else
      animate.start({
        x: [20, 0],
        opacity: 1,
        transition: { delay: 0.7, duration: 0.4, ease: [0.25, 1, 0.25, 1] },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNavRedirecting]);

  useEffect(() => {
    isGridView && animation(animate, 1);

    const workPageCond =
      pathname === ROUTES.WORK &&
      !isGridView &&
      !isMobile() &&
      !(window.innerWidth < breakpointDesktop);

    const onScroll = () => {
      const percentageScroll = calculateScrollPercentage();
      const animatedLogoCurrent = animatedLogoRef?.current;

      if (workPageCond) {
        if (+percentageScroll > 0.5) {
          if (isEnteringWorkPageContent) animation(animate, 1);
          else animation(animate, 0);
        } else animation(animate, 1);
      }

      if (+percentageScroll < 0.1) {
        setIsCopyLogo(false);
        animatedLogoCurrent?.showLayers();
      } else {
        setIsCopyLogo(true);
        animatedLogoCurrent?.hideLayers();
      }
    };

    if (ANIMATED_LOGO_PAGES.includes(pathname)) {
      onScroll();
      window.addEventListener('scroll', onScroll);

      return () => (
        window.removeEventListener('scroll', onScroll), setIsCopyLogo(true)
      );
    } else setIsCopyLogo(true);
  }, [isEnteringWorkPageContent, isGridView, pathname]);

  useEffect(() => {
    isHeaderTextVisible
      ? animation(animate, 1, 0.2)
      : animation(animate, 0, 0.2);
  }, [isHeaderTextVisible]);

  useEffect(() => {
    if (isMenuOpen) {
      setTimeout(
        () => (setIsCopyLogo(false), animatedLogoRef?.current?.showLayers()),
        100
      );
    } else {
      setHeaderText({
        default:
          copy.navbar.navLinks.find(({ href }) => href === pathname)
            ?.headerText || DEFAULT_HEADER_TEXT.default,
      });

      if (ANIMATED_LOGO_PAGES.includes(pathname))
        setTimeout(() => setIsCopyLogo(false), 200);
      else setTimeout(() => setIsCopyLogo(true), 200);
    }
  }, [isMenuOpen, pathname]);

  const colorCondition =
    LIGHT_HEADER.includes(route) && !isMenuOpen
      ? ColorNames.black
      : isMenuOpen && isBackgroundFromCMS
      ? ColorNames.black
      : ColorNames.white;

  const conditions = { colorCondition };

  const textHoveringColors =
    copy.navbar?.linksHoverColor ||
    colors[copy.navbar?.linksHoverDefaultColors];

  const renderHeaderCopy = useCallback(
    ({
      normal,
      colored,
      default: defaultText,
      color,
    }: {
      normal: string;
      colored: string;
      default: string;
      color: string;
    }) => {
      const coloredText = (
        <S.TransformedText>
          <Text
            as="h4"
            size="heading4"
            text={colored ? normal : defaultText?.split(' ')[0]}
            color={colorCondition}
          />
          <Text as="pre" size="heading4" text=" " color={colorCondition} />
          <S.MotionText animate={animate}>
            <S.MotionText
              key={`${colored}`}
              initial={!colored && isGridView && { opacity: 0.6 }}
              animate={!colored && isGridView && { opacity: 1 }}
              transition={{ duration: 0.4 }}
            >
              <Text
                as="h4"
                size="heading4"
                text={
                  colored
                    ? colored
                    : defaultText
                        ?.split(' ')
                        .slice(1, defaultText.length)
                        .join(' ')
                }
                color={colorCondition}
                style={color ? { color: color } : {}}
              />
            </S.MotionText>
          </S.MotionText>
        </S.TransformedText>
      );

      return (
        <AnimatePresence mode="wait">
          <S.TextContainer>{coloredText}</S.TextContainer>
        </AnimatePresence>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMenuOpen, headerText, route]
  );

  const animatedLogo = (
    <AnimatedLogo
      ref={animatedLogoRef}
      animationName={Pages[route.slice(1, route.length)]}
      invertColor={!!LIGHT_HEADER.includes(route) || isMenuOpen}
    />
  );

  const copyLogo =
    isHovered && copy.navbar?.hoveredText
      ? animatedLogo
      : renderHeaderCopy(headerText);

  const logoContent = (
    <S.ContentWrapper
      onClick={() => {
        push(ROUTES.HOME);
        setIsMenuOpen(false);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isCopyLogo ? copyLogo : animatedLogo}
    </S.ContentWrapper>
  );

  const normalHeader = (
    <>
      {logoContent}

      <MenuWrapper conditions={conditions} />
    </>
  );

  const renderLinks = (links: NavLinkCMS[]) =>
    links.map(({ label, href, isExternal }) => (
      <S.NavElement
        key={uniqueId()}
        whileHover={{
          color: textHoveringColors,
        }}
      >
        <LinkComp href={href} isExternal={isExternal}>
          {label}
        </LinkComp>
      </S.NavElement>
    ));

  const languageSelector = (
    <S.LanguageSelectorWrapper
      initial={{ display: 'block' }}
      animate={{ display: isLanguageSelectorOpen ? 'block' : 'none' }}
      transition={{ delay: isLanguageSelectorOpen ? 0 : 1, duration: 0 }}
    >
      <S.LanguageSelectorContainer
        initial={{ y: '-100%' }}
        animate={{ y: isLanguageSelectorOpen ? 0 : '-100%', originY: 0 }}
        transition={{
          duration: 1,
          ease: isLanguageSelectorOpen
            ? [0.04, 0.62, 0.23, 0.98]
            : [0.82, 0, 0.38, 3],
        }}
      >
        <ul>
          {languages.map(({ name, shortName }) => (
            <S.Language
              key={uniqueId()}
              whileHover={{
                color: textHoveringColors,
              }}
              onClick={() => (
                handleLanguage(router, shortName),
                setIsLanguageSelectorOpen(false)
              )}
              isBolder={shortName.includes('zh-CN')}
              isSelected={shortName.includes(locale) && textHoveringColors}
            >
              {name}
            </S.Language>
          ))}
        </ul>
      </S.LanguageSelectorContainer>
    </S.LanguageSelectorWrapper>
  );

  const navigationList = useMemo(
    () => (
      <S.NavList
        isBlackTextColor={isSearchOverlayOpen && isBackgroundFromCMS}
        disableOnMobile
      >
        {renderLinks(navLinks)}

        <S.NavElement
          onClick={() => setIsSearchOverlayOpen(true)}
          whileHover={{
            color: textHoveringColors,
          }}
        >
          <span>
            <SvgSearch />
          </span>
        </S.NavElement>

        <S.NavElement
          onClick={() => setIsLanguageSelectorOpen(!isLanguageSelectorOpen)}
          whileHover={{
            color: textHoveringColors,
          }}
          svgHighlight={isLanguageSelectorOpen && textHoveringColors}
        >
          <span>
            <SvgGlobe />
          </span>
        </S.NavElement>
      </S.NavList>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [copy, isSearchOverlayOpen, isLanguageSelectorOpen]
  );

  const homePageHeader = (
    <>
      {isMenuOpen && !isSearchOverlayOpen ? logoContent : <div />}

      <MenuWrapper conditions={conditions} disableOnDesktop>
        {!isMenuOpen && (
          <S.NavList customLinkSize>{renderLinks(contactLinks)}</S.NavList>
        )}
      </MenuWrapper>

      {navigationList}
      {languageSelector}
    </>
  );

  return (
    <S.Wrapper {...rest} ref={globeRef}>
      <S.Container id="header_container">
        {DISABLED_HEADER_LOGO.includes(route) ? homePageHeader : normalHeader}
      </S.Container>
    </S.Wrapper>
  );
};

Header.defaultProps = defaultProps;

export default memo(Header);

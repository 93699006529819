import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { colors, layout } from 'utils/styles/theme';

export const FlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ToastWrapper = styled.div`
  width: 100%;

  position: fixed;
  left: 0;
  bottom: 50px;
  z-index: ${layout.zIndex.overlay};

  ${FlexCenter}
`;

export const Container = styled(motion.div)`
  height: 100%;
  min-height: 40px;
  opacity: 0;
  background-color: ${colors.white};
  border-radius: 10px;
  ${FlexCenter}
  padding: 0 20px;
  transform: scale(0);
`;

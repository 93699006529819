import { NextRouter } from 'next/router';

export const languages = [
  { name: 'Global', shortName: 'en' },
  // { name: 'Deutschland', shortName: 'de' }, // Disabled temporary
  { name: 'España', shortName: 'es' },
  { name: '中国', shortName: 'zh-CN' },
  { name: 'Brasil', shortName: 'pt-BR' },
  // { name: 'netherlands', shortName: 'nl-NL' },  // Disabled temporary
];

export const handleLanguage = (router: NextRouter, shortName: string) =>
  router.push(router.asPath, router.asPath, { locale: shortName });

import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import BaseRadioBlock from 'components/RadioBlock/RadioBlock';
import BaseSocialsPanel from 'components/SocialsPanel/SocialsPanel';
import Text from 'components/Text/Text';
import { ReactComponent as WILD_ARROW } from 'svgs/wildArrow.svg';
import { setTypography, setVh } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors, layout } from 'utils/styles/theme';

interface WrapperProps {
  backgroundColor: string;
}

interface LanguageIndicatorProps {
  $isBackgroundFromCMS: boolean;
}

interface SearchWrapperProps {
  isBackgroundFromCMS: boolean;
}

const BASIC_OPACITY = 0.5;
const { max } = layout.columns;

export const Wrapper = styled(motion.div)<WrapperProps>`
  width: 100%;
  height: ${setVh(100)};

  overflow: scroll;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  position: fixed;
  inset: 0;
  z-index: ${layout.zIndex.menuModal};

  background-color: ${({ backgroundColor }) =>
    backgroundColor
      ? colors[backgroundColor] ?? backgroundColor
      : colors.black};

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      label,
      span,
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      li,
      a,
      button {
        color: ${colors.black};
      }
    `};
`;

export const Container = styled(motion.div)`
  position: relative;
  margin: 117px 16px 251px;

  ${mediaDesktop(`
    margin: 160px 124px 210px;
  `)};
`;

export const MenuContent = styled.div`
  position: relative;

  display: grid;
  grid-row-gap: 56px;

  ${mediaDesktop(`
    grid-template-columns: repeat(${max}, 1fr);
  `)};
`;

export const NavList = styled(motion.ul)`
  display: grid;
  grid-row-gap: 0;

  position: relative;

  > li {
    transition: opacity 0.2s ease-in;
  }

  &:hover {
    > li {
      opacity: ${BASIC_OPACITY};

      &:hover {
        opacity: 1;
      }
    }
  }

  ${mediaDesktop(`
    grid-row-gap: 4px;
  `)};
`;

export const Nav = styled.nav`
  position: relative;
  height: max-content;

  ${({ theme: { locale } }) => setTypography('heading1', locale)};

  ${mediaDesktop(`
    grid-column: 1/5;
  `)};
`;

export const Info = styled(motion.div)`
  display: contents;

  ${mediaDesktop(`
    display: grid;
    grid-row-gap: 56px;
    grid-column: 9/${max};
  `)};
`;

export const SearchButtonWrapper = styled(motion.div)`
  position: absolute;
  height: 100%;
  bottom: 0;

  display: flex;
  flex-direction: column;
  place-content: end;
`;

export const SearchButton = styled.button`
  width: max-content;
  color: ${colors.dustyGray};
  transform: translateY(100%);

  ${({ theme: { locale } }) => setTypography('heading1', locale)}
  text-align: left;
`;

export const LanguageLabel = styled(Text)`
  cursor: pointer;
`;

const INDICATOR_WIDTH = 55;
export const LanguageIndicator = styled(WILD_ARROW)<LanguageIndicatorProps>`
  color: ${({ $isBackgroundFromCMS }) =>
    $isBackgroundFromCMS ? colors.black : colors.white};
  width: ${INDICATOR_WIDTH}px;
  padding-right: 25px;
`;

export const SocialsPanel = styled(BaseSocialsPanel)`
  margin-left: ${INDICATOR_WIDTH}px;
  ${({ theme: { locale } }) => setTypography('smallBody', locale)}
`;

export const RadioBlockWrapper = styled.div`
  margin-top: 50px;
  ${mediaDesktop(`
    margin-top: 0;
  `)}
`;
export const RadioBlock = styled(BaseRadioBlock)`
  label {
    opacity: ${BASIC_OPACITY};
  }

  input[type='radio']:checked + label {
    opacity: 1;
  }
`;

export const MailList = styled.ul`
  display: grid;
  margin-left: ${INDICATOR_WIDTH}px;
  grid-row-gap: 20px;

  span {
    cursor: pointer;
  }

  ${mediaDesktop(`
    grid-row-gap: 10px;
    grid-template-columns: 50% 50%;
    margin-left: calc(-100% + ${INDICATOR_WIDTH * 2}px);
  `)}
`;

export const MailListItem = styled.li`
  ${mediaDesktop(`
    display: contents;

    > :nth-child(even) {
      text-align: left;
    }
    > :nth-child(odd) {
      text-align: right;
      margin-right: 32px;
    }
  `)}
`;

export const SearchWrapper = styled.div<SearchWrapperProps>`
  width: 100%;
  height: 100%;

  bottom: 0;

  position: relative;

  ${({ isBackgroundFromCMS }) =>
    isBackgroundFromCMS && `color: ${colors.black}`};
`;

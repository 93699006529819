import styled from 'styled-components';

interface LinkItemProps {
  color?: string;
}

export const LinkItem = styled.a<LinkItemProps>`
  color: ${({ color }) => (color ? color : 'inherit')};
  text-decoration: none;
`;

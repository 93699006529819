import { createGlobalStyle, css } from 'styled-components';

import { setTypography } from './mixins';
import resetCSS from './reset';
import { setScalableFontSize } from './responsive';
import { colors } from './theme';

const style = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    user-select: text;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::selection {
    background: ${colors.white};
    color: ${colors.black};
    text-shadow: none;
  }

  ::-moz-selection {
    background: ${colors.white};
    color: ${colors.black};
    text-shadow: none;
  }

  html,
  body {
    width: 100%;
    height: min-content;
  }

  html {
    ${setScalableFontSize()}
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  body {
    background: ${colors.black};
    color: ${colors.white};
    ${({ theme: { locale } }) => setTypography('body', locale)}
  }

  button,
  input,
  textarea,
  select {
    -webkit-appearance: none;
    background: none;
    font-family: inherit;
    border: none;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
    border-radius: 0;
  }

  input,
  textarea,
  select {
    outline: none;
  }

  svg {
    outline: none;
    display: block;
  }

  button,
  div,
  a,
  span {
    -webkit-tap-highlight-color: transparent;
  }

  a {
    color: inherit;
  }

  button {
    cursor: pointer;
  }

  .accessibility-focus :focus,
  .accessibility-focus button:focus {
    outline: auto 5px Highlight; /* for non-webkit browsers */
    outline: auto 5px -webkit-focus-ring-color; /* for webkit browsers */
  }
`;

export default createGlobalStyle`
  ${resetCSS}
  ${style}
`;

import { useEffect, useState } from 'react';

export const useClipboard = () => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [copiedText, setCopiedText] = useState<string>('');

  useEffect(() => {
    isCopied && setTimeout(() => setIsCopied(false), 1000);
  }, [isCopied]);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      setIsCopied(true);
    } catch (err) {
      // TODO: Error message if needed
      setIsCopied(false);
    }
  };

  return {
    isCopied,
    copiedText,
    copyToClipboard,
  };
};

export default useClipboard;

import { AnimationControls } from 'framer-motion';

export const animation = (
  animate: AnimationControls,
  opacity = 1,
  duration?: number
) =>
  animate.start({
    opacity: opacity,
    transition: { duration: duration || 0.4 },
  });

import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors, layout } from 'utils/styles/theme';

interface WrapperProps {}

interface LocationProps {
  textColor?: string;
}

interface TopContainerProps {
  isBottomText: boolean;
}

interface ColumnProps {
  textColor?: string;
}

const columns = layout.columns.fourThirds;
const border = '1px solid rgba(255, 255, 255, 0.2)';

export const Wrapper = styled.footer<WrapperProps>`
  position: relative;
  z-index: ${layout.zIndex.overContent};

  background-color: ${colors.black};
`;

export const TopContainer = styled.div<TopContainerProps>`
  border-top: ${border};
  border-bottom: ${border};
  padding: 41px 16px 56px;

  ${mediaTablet(`
    padding: 88px 124px 160px;
  `)};

  ${({ isBottomText }) =>
    isBottomText &&
    css`
      padding-bottom: 0 !important;
    `}
`;

export const BottomContainer = styled.div`
  color: ${colors.silverChalice};

  padding: 40px 16px 48px;

  h5 {
    font-family: 'Sequel Sans VFB1';
    font-style: normal;
    font-weight: 110;
    font-size: 13px;
    line-height: 18px;
  }

  ${mediaTablet(`
    display: flex;
    justify-content: space-between;

    padding: 16px 25px 20px;
  `)};
`;

export const Location = styled.div<LocationProps>`
  display: grid;
  grid-template-columns: repeat(${columns}, 1fr);

  margin-bottom: 56px;

  h4,
  time {
    ${({ textColor }) =>
      textColor && `color: ${colors[textColor] || textColor}`};
    text-transform: uppercase;
  }

  ${mediaTablet(`
    margin-bottom: 80px;
  `)};
`;

export const Content = styled.div`
  display: grid;
  grid-row-gap: 40px;

  ${mediaTablet(`
    grid-row-gap: 0;
    grid-template-columns: repeat(${columns}, 1fr);
  `)}
`;

export const Column = styled.article<ColumnProps>`
  ${({ theme: { locale } }) => setTypography('small', locale)};

  h5 {
    margin-bottom: 2px;
  }

  ${({ textColor }) =>
    textColor?.length &&
    css`
      & a:hover {
        color: ${colors[textColor] ?? textColor};
      }
    `}
`;

export const LeftBottomSide = styled.div`
  ${({ theme: { locale } }) => setTypography('small', locale)};
  font-family: 'Sequel Sans VFB1';
  margin-bottom: 32px;

  ${mediaTablet(`
    font-style: normal;
    font-weight: 110;
    font-size: 13px;
    line-height: 18px;

    display: inline-flex;
    margin-bottom: 0;

    div:not(:last-child) {
      margin-right: 24px;
    }
  `)};
`;

export const BottomTextWrapper = styled.div`
  padding: 27px 0;

  ${mediaTablet(`
    padding: 74px 0;
  `)};
`;

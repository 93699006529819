import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors, layout } from 'utils/styles/theme';
import { breakpointDesktop } from 'utils/styles/vars';

interface WrapperProps {}

interface NavListProps {
  isBlackTextColor?: boolean;
  disableOnMobile?: boolean;
  customLinkSize?: boolean;
}

interface MenuWrapperProps {
  textHoveringColor: string;
  disableOnDesktop?: boolean;
}

interface NavElementProps {
  svgHighlight?: string;
}

const renderHoveringStyle = hoveringColor =>
  hoveringColor
    ? `
    & *:hover {
      transition: color 0.4s ease-in-out;
      color: ${colors[hoveringColor] ?? hoveringColor};
    }
  `
    : '';
const renderDisplayOnDesktop = display =>
  display
    ? css`
        display: none;
      `
    : '';

export const Wrapper = styled.header<WrapperProps>`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${layout.zIndex.navbar};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;

  ${mediaDesktop(`
    padding: 16px 25px;
  `)}
`;

export const TextContainer = styled(motion.div)`
  display: flex;
  position: relative;
`;

export const TransformedText = styled(motion.div)`
  display: flex;

  h4 {
    transition: color 0.4s ease-in-out;
  }
`;

export const MotionText = styled(motion.div)``;

export const ContentWrapper = styled.div`
  min-width: 50%;
  cursor: pointer;
`;

export const MenuWrapper = styled(motion.div)<MenuWrapperProps>`
  display: flex;
  cursor: pointer;

  ${({ textHoveringColor, disableOnDesktop }) =>
    mediaDesktop(`
    ${renderHoveringStyle(textHoveringColor)}
    ${renderDisplayOnDesktop(disableOnDesktop)}
  `)}
`;

export const NavList = styled.ul<NavListProps>`
  display: flex;

  ${({
    theme: { locale },
    isBlackTextColor,
    disableOnMobile,
    customLinkSize,
  }) =>
    css`
      ${isBlackTextColor
        ? `
            a,
            span {
              color: ${colors.black};
            }
          `
        : ''}

      ${disableOnMobile
        ? `
            @media (max-width: ${breakpointDesktop - 1}px) {
              display: none;
            }
          `
        : ''}

      ${customLinkSize
        ? `
            li a {
              ${setTypography('heading4', locale)}
              margin-right: 30px;
            }
          `
        : ''}
    `};
`;

export const NavElement = styled(motion.li)<NavElementProps>`
  margin: auto 0 auto 10px;
  transition: color 0.2s ease-out;

  span {
    cursor: pointer;
  }

  ${({ svgHighlight }) =>
    svgHighlight &&
    css`
      svg {
        color: ${colors[svgHighlight] ?? svgHighlight};
      }
    `}

  ${mediaDesktop(`
    margin: auto 0 auto 30px;
  `)}
`;

export const LanguageSelectorWrapper = styled(motion.div)`
  position: absolute;
  top: 61px;
  right: 0;
  text-align: right;

  overflow: hidden;
`;

export const LanguageSelectorContainer = styled(motion.div)`
  text-align: right;
  padding: 21px 44px 21px 27px;
`;

interface LanguageProps {
  isBolder: boolean;
  isSelected: string;
}

export const Language = styled(motion.li)<LanguageProps>`
  ${({ isBolder }) => isBolder && 'font-weight: 400'};
  ${({ isSelected }) =>
    isSelected && `color: ${colors[isSelected] ?? isSelected}`};
  cursor: pointer;
`;

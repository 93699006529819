import { uniqueId } from 'lodash';
import { compiler } from 'markdown-to-jsx';
import React from 'react';

import Text from 'components/Text/Text';
import { colors, validFontCategories } from 'utils/styles/theme';

import { checkCustomTags, OVERRIDE_TAGS } from './RichText.utils';

import * as S from './RichText.styles';

export interface RichTextProps {
  richText: string;
  defaultSpacing?: boolean;
  defaultFormatting?: boolean;
  size?: typeof validFontCategories[number];
  as?: any;
}

const defaultProps: Partial<RichTextProps> = {
  richText: '',
  defaultSpacing: false,
  defaultFormatting: false,
};

const RichText: React.FunctionComponent<RichTextProps> = ({
  defaultSpacing,
  richText,
  defaultFormatting,
  size,
  as,
  ...rest
}) => {
  const cmsFormattingOverrides =
    defaultFormatting &&
    OVERRIDE_TAGS.reduce((acc, tag) => {
      acc[tag] = ({ children }) =>
        size && as && <Text as={as} size={size} text={children} />;
      return acc;
    }, {});

  const RTE = compiler(richText || '', {
    wrapper: null,
    overrides: {
      a: <a target="_blank"></a>,
      set: ({ children, color }) => <span style={{ color }}>{children}</span>,
      pink: ({ children }) => (
        <span style={{ color: colors.poppingPink }}>{children}</span>
      ),
      violet: ({ children }) => (
        <span style={{ color: colors.ultraViolet }}>{children}</span>
      ),
      green: ({ children }) => (
        <span style={{ color: colors.chargedGreen }}>{children}</span>
      ),
      blue: ({ children }) => (
        <span style={{ color: colors.electricBlue }}>{children}</span>
      ),
      ...cmsFormattingOverrides,
    },
    forceBlock: defaultFormatting,
    // @ts-ignore
  }).map(text =>
    (typeof text === 'string' || text instanceof String) &&
    !defaultFormatting ? (
      <p key={uniqueId()}>{text}</p>
    ) : (
      text
    )
  );

  const customRTE = checkCustomTags(richText) ? (
    <Text as={as} size={size} text={RTE} {...rest} />
  ) : (
    RTE
  );
  const regularRTE = defaultFormatting ? customRTE : <div {...rest}>{RTE}</div>;
  const spacedRTE = <S.DefaultSpacing {...rest}>{RTE}</S.DefaultSpacing>;

  return <>{defaultSpacing ? spacedRTE : regularRTE}</>;
};

RichText.defaultProps = defaultProps;

export default RichText;

import Link from 'next/link';
import React, { FunctionComponent, ReactNode } from 'react';

import {
  triggerDefaultCursor,
  triggerScaleUpCursor,
} from 'utils/cursorController';

import * as S from './LinkComp.styles';

export interface LinkCompProps {
  href: string;
  isExternal?: boolean;
  color?: string;
  children: ReactNode;
  isCursorHoverState?: boolean;
  onClick?: () => void;
  onlyLink?: boolean;
}

export interface LinkProps {
  href: string;
  color?: string;
  children: ReactNode;
}

const defaultProps: Partial<LinkCompProps> = {
  children: 'link',
  href: undefined,
};

const InternalLink: FunctionComponent<LinkProps> = ({
  children,
  href,
  color,
}) => (
  <Link scroll={false} href={href || ''} passHref>
    <S.LinkItem tabIndex={0} title={href} color={color}>
      {children}
    </S.LinkItem>
  </Link>
);

const ExternalLink: FunctionComponent<LinkProps> = ({
  children,
  href,
  color,
}) => (
  <S.LinkItem
    tabIndex={0}
    color={color}
    href={href}
    target="_blank"
    rel="noreferrer"
    title={href}
    onDragStart={e => e.preventDefault()}
  >
    {children}
  </S.LinkItem>
);

const LinkComp: FunctionComponent<LinkCompProps> = ({
  children,
  href,
  isExternal,
  isCursorHoverState = true,
  onlyLink,
  ...rest
}) => {
  const handleMouseEnter = () => {
    if (!isCursorHoverState) return;
    triggerScaleUpCursor();
  };

  const handleMouseLeave = () => {
    if (!isCursorHoverState) return;
    triggerDefaultCursor();
  };

  if (onlyLink) {
    return isExternal ? (
      <ExternalLink href={href}>{children}</ExternalLink>
    ) : (
      <InternalLink href={href}>{children}</InternalLink>
    );
  }

  return (
    <>
      <div
        {...rest}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isExternal ? (
          <ExternalLink href={href}>{children}</ExternalLink>
        ) : (
          <InternalLink href={href}>{children}</InternalLink>
        )}
      </div>
    </>
  );
};

LinkComp.defaultProps = defaultProps;

export default LinkComp;

import { FooterDataProps } from 'utils/sharedStrapiQueries/sharedTypes';

interface CordsDistanceTypes {
  lat1: number;
  lon1: number;
  lat2: number;
  lon2: number;
}

export const INITIAL_DATA = {
  name: '',
  heading: '',
  telephoneNumber: '',
  email: '',
  bottomContactsInfo: [],
  links: [],
  copyright: '',
  socialsToastText: '',
  socials: [],
  socialsLinks: [],
  timeZone: 'Europe/London',
  coordinates: {
    office: {
      latitude: 0,
      longitude: 0,
    },
  },
};

export const cordsDistance = ({
  lat1,
  lon1,
  lat2,
  lon2,
}: CordsDistanceTypes) => {
  const radlat1 = (Math.PI * Number(lat1)) / 180;
  const radlat2 = (Math.PI * Number(lat2)) / 180;
  const theta = Number(lon1) - Number(lon2);
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344;

  return dist;
};

export const getTheNearestLocations = (
  userCords: { latitude: number; longitude: number },
  offices: FooterDataProps['footer']
) =>
  offices.map(({ coordinates }): number =>
    Number(
      cordsDistance({
        lat1: userCords.latitude,
        lon1: userCords.longitude,
        lat2: coordinates?.office?.latitude || 0,
        lon2: coordinates?.office?.longitude || 0,
      }).toFixed(2)
    )
  );

export const userLocation = async () => {
  const url = 'https://get.geojs.io/v1/ip/geo.json';

  try {
    const response = await fetch(url);

    const { latitude, longitude, organization_name } = JSON.parse(
      await response.text()
    );

    return {
      latitude,
      longitude,
      organization_name,
    };
  } catch (e) {
    console.log(e);

    return {
      latitude: 0,
      longitude: 0,
    };
  }
};

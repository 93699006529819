import { CursorVariantType } from 'utils/sharedStrapiQueries/sharedTypes';

let cursorChangeEvent: Event;

export let currentEvent: string;
export let cursorLabel = '';
export const EVENT_NAME = 'dbp.cursorChange';
export const CURSOR_STATE: CursorVariantType = {
  default: 'default',
  disable: 'disable',
  light: 'light',
  dark: 'dark',
  scaleUp: 'scaleUp',
  drag: 'drag',
  moveMe: 'moveMe',
  moveMeGrey: 'moveMeGrey',
};

const dispatch = () => {
  if (!cursorChangeEvent) {
    cursorChangeEvent = new Event(EVENT_NAME);
  }

  window.dispatchEvent(cursorChangeEvent);
};

export const triggerDefaultCursor = () => {
  currentEvent = CURSOR_STATE.default;
  dispatch();
};

export const triggerDisableCursor = () => {
  currentEvent = CURSOR_STATE.disable;
  dispatch();
};

export const triggerLightCursor = () => {
  currentEvent = CURSOR_STATE.light;
  dispatch();
};

export const triggerDarkCursor = () => {
  currentEvent = CURSOR_STATE.dark;
  dispatch();
};

export const triggerScaleUpCursor = () => {
  currentEvent = CURSOR_STATE.scaleUp;
  dispatch();
};

export const triggerDragCursor = () => {
  currentEvent = CURSOR_STATE.drag;
  dispatch();
};

export const triggerMoveMeCursor = (isGrey?: boolean) => {
  currentEvent = isGrey ? CURSOR_STATE.moveMeGrey : CURSOR_STATE.moveMe;
  dispatch();
};

export const setCursorLabel = (label: string) => (cursorLabel = label);

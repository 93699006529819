import isValidProp from '@emotion/is-prop-valid';
import * as Sentry from '@sentry/react';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import Cookies from 'js-cookie';
import type { AppProps } from 'next/app';
import React, { useCallback, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useEffectOnce } from 'usehooks-ts';

import Cursor from 'components/Cursor/Cursor';
import Layout from 'components/Layout/Layout';
import PageTransition from 'components/PageTransition/PageTransition';
import { Overlay } from 'hooks/useOverlayPortal';
import { setupStoreDevTools, useCopyStore, useGlobalStore } from 'store';
import Head from 'u9/components/Head/Head';
import Version from 'u9/components/versioning/Version/Version';
import VersionScreen from 'u9/components/versioning/VersionScreen/VersionScreen';
import { keyboardFocus } from 'utils';
import Analytics from 'utils/analytics';
import { appState } from 'utils/appState';
// import NonFunctionals from 'u9/containers/NonFunctionals/NonFunctionals';
import customFonts from 'utils/styles/fonts';
import GlobalStyles from 'utils/styles/globalStyles';
import theme from 'utils/styles/theme';

import 'intersection-observer';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    enabled: true,
    dsn: process.env.SENTRY_DSN,
  });
}

const App = ({ Component, pageProps, router }: AppProps) => {
  const [isMounted, setMounted] = useState(false);
  const { copy, setCopy } = useCopyStore();
  const { cookiesConfirmation } = useGlobalStore();

  const cookieAnalytics = Cookies.get('dbp.analytics');

  const keyboardEvent = useCallback((e: KeyboardEvent) => keyboardFocus(e), []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      appState.analytics = new Analytics();
      appState.analytics._initializeTracker();
    }
  }, [cookieAnalytics, cookiesConfirmation]);

  useEffectOnce(() => {
    if (process.env.IS_DEBUG) setupStoreDevTools();
    if (pageProps.initialCopy) setCopy(pageProps.initialCopy);

    setMounted(true);
    localStorage.removeItem('redirectionPopupVisibility__hidden');
    document.addEventListener('keydown', keyboardEvent, false);
    return () => document.removeEventListener('keydown', keyboardEvent, false);
  });

  return (
    <React.StrictMode>
      <ThemeProvider theme={{ ...theme, locale: router.locale }}>
        <MotionConfig isValidProp={isValidProp}>
          <GlobalStyles />
          <style dangerouslySetInnerHTML={{ __html: customFonts }} />
          <Overlay />

          <Head {...(pageProps.initialCopy || copy).head} />

          {/* <NonFunctionals
            // If the non-functionals are the page component, pass the initial copy
            // fetched from the server instead of the stored copy
            initialCopy={
              router.route === ROUTES.NOT_FOUND ? pageProps.initialCopy : copy
            }
            router={router}
          > */}
          <AnimatePresence mode="wait" initial={false}>
            <Layout>
              <AnimatePresence
                mode="wait"
                onExitComplete={() => {
                  if (typeof window !== 'undefined')
                    window.scrollTo({ top: 0 });
                }}
              >
                <PageTransition key={`${router.route} ${router.locale}`}>
                  <AnimatePresence mode="wait">
                    <Component router={router} {...pageProps} />
                  </AnimatePresence>
                </PageTransition>
              </AnimatePresence>
            </Layout>
          </AnimatePresence>
          <Cursor />
          {/* </NonFunctionals> */}

          {process.env.IS_DEBUG && <Version />}
          {isMounted
            ? process.env.IS_DEBUG &&
              window.location.hash === '#version' && <VersionScreen />
            : null}
        </MotionConfig>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;

import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

class Analytics {
  static TRACKING_ID = 'GTM-K7HHTZZ';

  private cookieAnalytics = Cookies.get('dbp.analytics');
  private cookieCondition =
    this.cookieAnalytics && this.cookieAnalytics.toLowerCase() === 'true';

  _initializeTracker() {
    if (this.cookieCondition) {
      TagManager.initialize({
        gtmId: Analytics.TRACKING_ID,
      });
    } else console.log('### Analytics disabled ###');
  }
}

export default Analytics;

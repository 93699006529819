import { motion } from 'framer-motion';
import styled from 'styled-components';

export const RadioBlockWrapper = styled.form`
  display: inline-flex;
  height: max-content;
`;

export const Indicator = styled(motion.div)`
  transition: transform 0.2s ease-out;
  place-self: center;
`;

export interface RadiosProps {
  rows: number;
}
export const Radios = styled.div<RadiosProps>`
  display: grid;
  grid-template-rows: repeat(${({ rows }) => rows}, min-content);
`;

export const IndicatorWrapper = styled.div<RadiosProps>`
  display: grid;
  grid-template-rows: repeat(${({ rows }) => rows}, 1fr);
`;

export const RadioInput = styled.input`
  width: 0;
`;

export const RadioWrapper = styled.span`
  display: flex;
  align-items: center;
`;

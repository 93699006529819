import { useRouter } from 'next/router';
import React, { FunctionComponent, ReactNode, useEffect } from 'react';

import CookieBar from 'components/CookieBar/CookieBar';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import Menu from 'components/Menu/Menu';
import RedirectPopup from 'components/RedirectPopup/RedirectPopup';
import { useCopyStore, useGlobalStore } from 'store';
import { ROUTES } from 'utils/routes';
import { pageMotionProps } from 'utils/styles/animations';
import { ColorNames } from 'utils/styles/theme';

import { getTheNearestLocations, userLocation } from './Layout.utils';

import * as S from './Layout.styles';

export interface LayoutProps {
  children: ReactNode | ReactNode[];
}

const defaultProps: Partial<LayoutProps> = {};

const NO_FOOTER = [ROUTES.PLAY, ROUTES.HOME] as string[];
const LIGHT_HEADER = [ROUTES.PLAY, ROUTES.INSIGHT_ARTICLE] as string[];

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  const { route } = useRouter();
  const { copy } = useCopyStore();

  const { locationData, setLocationData } = useGlobalStore();

  useEffect(() => {
    if (copy.footer) {
      userLocation().then(({ latitude, longitude }) => {
        const nearestLocations = getTheNearestLocations(
          { latitude, longitude },
          copy.footer?.footer
        );
        const nearestData =
          copy.footer.footer[
            nearestLocations.indexOf(Math.min(...nearestLocations))
          ];
        setLocationData(nearestData);
      });
    }
  }, [copy.footer]);

  return (
    <S.Wrapper
      backgroundColor={
        LIGHT_HEADER.includes(route) ? ColorNames.white : ColorNames.black
      }
    >
      <CookieBar cookieBarData={copy.cookieBar} />
      <RedirectPopup {...copy.redirectPopup} />
      <Header />
      <Menu location={locationData} />

      <S.Content id="layout-content" {...pageMotionProps}>
        {children}
      </S.Content>

      {!NO_FOOTER.includes(route) && (
        <Footer location={locationData} {...copy.footer} />
      )}
    </S.Wrapper>
  );
};

Layout.defaultProps = defaultProps;

export default Layout;

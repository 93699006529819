import create from 'zustand';

import { OpportynityType } from 'containers/JoinUsPage/JoinUsPage.types';

export interface GlobalStoreType {
  isOpportunityListOpen: boolean;
  isOpportunityOpen: boolean;
  isFaqOpen: boolean;
  activeOpportunity: OpportynityType;
  openOpportunityList: () => void;
  openOpportunity: (state: OpportynityType) => void;
  openFaq: () => void;
  closeOverlay: () => void;
  backToOpportunities: () => void;
}

const defaultState = {
  isOpportunityListOpen: false,
  isOpportunityOpen: false,
  isFaqOpen: false,
};

const useGlobalStore = create(
  (
    set: (partial: (store: GlobalStoreType) => Partial<GlobalStoreType>) => void
  ) => {
    return {
      ...defaultState,
      activeOpportunity: null,
      openOpportunityList: () => {
        set(() => ({
          ...defaultState,
          isOpportunityListOpen: true,
        }));
      },
      openOpportunity: state => {
        set(() => ({
          ...defaultState,
          isOpportunityOpen: true,
          activeOpportunity: state,
        }));
      },
      openFaq: () => {
        set(() => ({
          ...defaultState,
          isFaqOpen: true,
        }));
      },
      closeOverlay: () => {
        set(() => ({
          ...defaultState,
          activeOpportunity: null,
        }));
      },
      backToOpportunities: () => {
        set(() => ({
          ...defaultState,
          isOpportunityListOpen: true,
        }));
      },
    };
  }
);

export default useGlobalStore;

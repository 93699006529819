import { motion } from 'framer-motion';
import styled from 'styled-components';

import { mediaDesktop } from 'utils/styles/responsive';
import { colors, layout } from 'utils/styles/theme';

interface CookieBarWrapperProps {}

export const TextWrapper = styled.div`
  height: min-content;

  display: grid;
  column-gap: 16px;

  transition: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);

  & * {
    color: ${colors.lighterMineShaft};
  }

  ${mediaDesktop(`
    align-items: center;
  `)}
`;

export const CookieBarWrapper = styled(motion.div)<CookieBarWrapperProps>`
  position: fixed;
  display: grid;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${colors.white};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.14);
  overflow: hidden;
`;

export const CookieBarGrid = styled(motion.div)`
  display: grid;
  row-gap: 33px;
  padding: 24px 16px;
  transition: height 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);

  ${mediaDesktop(`
    grid-template-columns: 2fr 1fr;
    column-gap: 32px;
    align-items: center;

    padding: 17px 31px;
  `)}
`;

export const InfoWrapper = styled(motion.div)`
  display: inline-flex;

  & small {
    margin-right: 4px;
  }

  & a {
    text-decoration: underline;
    cursor: pointer;
  }

  ${mediaDesktop(`
    & * {
      height: fit-content;
    }
  `)}
`;

export const ButtonWrapper = styled(motion.div)`
  height: min-content;
  background-color: ${colors.white};
  z-index: ${layout.zIndex.overContent};

  margin-right: 10px;

  & button {
    line-height: 14px;
    letter-spacing: -0.1px;
    span {
      font-size: 14px;
    }
  }
`;

export const BottomContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { mediaTablet } from 'utils/styles/responsive';

interface WrapperProps {}

interface LottieWrapperProps {
  invertColor: boolean;
}

export const Wrapper = styled(motion.div)<WrapperProps>``;

export const LottieWrapper = styled.div<LottieWrapperProps>`
  width: fit-content;

  position: absolute;
  ${({ invertColor }) => invertColor && 'filter: invert(100%)'};

  svg {
    width: auto !important;
  }

  ${({ theme: { locale } }) =>
    locale === 'zh-CN'
      ? css`
          height: 61px;
          top: 6px;
          left: 7px;
          ${mediaTablet(`
      height: 83px;
      top: 9px;
      left: 12px;
    `)}
        `
      : css`
          height: 56px;
          top: 9px;
          left: 7px;
          ${mediaTablet(`
      height: 75px;
      top: 13px;
      left: 14px;
    `)}
        `}
`;

import React, { memo } from 'react';
import styled from 'styled-components';

export interface LoadingDotsProps {
  color?: string;
}

const defaultProps: Partial<LoadingDotsProps> = {
  color: '#000',
};

const SVG = styled.svg``;

const LoadingDots: React.FunctionComponent<LoadingDotsProps> = ({
  color,
  ...rest
}) => {
  return (
    <SVG
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="40px"
      height="10px"
      viewBox="0 0 80 20"
      {...rest}
    >
      <circle cx="10" cy="10" r="5" fill={color}>
        <animate
          attributeName="cx"
          from="10"
          to="40"
          dur="0.5s"
          calcMode="spline"
          keySplines="0.42 0 0.58 1"
          keyTimes="0;1"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="10" cy="10" r="0" fill={color}>
        <animate
          attributeName="r"
          from="0"
          to="5"
          dur="0.5s"
          calcMode="spline"
          keySplines="0.42 0 0.58 1"
          keyTimes="0;1"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="40" cy="10" r="5" fill={color}>
        <animate
          attributeName="cx"
          from="40"
          to="70"
          dur="0.5s"
          calcMode="spline"
          keySplines="0.42 0 0.58 1"
          keyTimes="0;1"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="70" cy="10" r="5" fill="#666">
        <animate
          attributeName="r"
          from="5"
          to="0"
          dur="0.5s"
          calcMode="spline"
          keySplines="0.42 0 0.58 1"
          keyTimes="0;1"
          repeatCount="indefinite"
        />
      </circle>
    </SVG>
  );
};

LoadingDots.defaultProps = defaultProps;

export default memo(LoadingDots);

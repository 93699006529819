import React from 'react';

import { IRadioButton } from './RadioBlock';

export const getRadioPosition = (
  radioButtons: IRadioButton[],
  ref: React.MutableRefObject<any>,
  value: string
) => {
  const index = radioButtons.findIndex(e => e.value === value);
  const row = ref.current.getBoundingClientRect().height / radioButtons.length;
  return index * row;
};

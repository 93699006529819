import styled from 'styled-components';

import { mediaDesktop } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const DefaultSpacing = styled.div`
  word-wrap: normal;

  & h1,
  h2,
  h3 {
    padding-top: 96px;
    padding-bottom: 32px;

    ${mediaDesktop(`
      padding-top: 104px;
    `)}

    &:first-child {
      padding-top: 0;
    }
  }

  & p {
    padding-bottom: 1em;

    &:last-child {
      padding-bottom: 0;
    }

    & + ul,
    & + ol,
    & + blockquote {
      margin-top: 14px;
    }
  }

  & h4,
  h5,
  h6 {
    padding-top: 96px;
    padding-bottom: 24px;

    ${mediaDesktop(`
      padding-top: 104px;
    `)}

    &:first-child {
      padding-top: 0;
    }
  }

  & ul {
    list-style-type: disc;
    font-weight: 110;
    margin: 32px 0 32px 16px;
  }

  & ol {
    list-style-type: decimal;
    margin: 32px 0 32px 18px;
  }

  & li {
    padding: 12px 0 0 16px;

    &:first-child {
      padding-top: 0;
    }
  }

  & blockquote {
    padding-left: 32px;
    margin: 32px 0;
    border-left: 3px solid ${colors.black};

    &:first-child {
      margin-top: 0;
    }
  }

  & > video {
    width: 100%;
  }
`;

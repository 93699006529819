import { ROUTES } from 'utils/routes';

export const ANIMATED_LOGO_PAGES = [
  ROUTES.HOME,
  ROUTES.WORK,
  ROUTES.ABOUT,
  ROUTES.JOIN_US,
  ROUTES.INSIGHTS,
  ROUTES.CASE_STUDY,
  ROUTES.AWARD,
  ROUTES.INSIGHT_ARTICLE,
  ROUTES.NOT_FOUND,
  ROUTES.ACCESSIBILITY,
  ROUTES.PRIVACY_POLICY,
  ROUTES.COOKIES,
  ROUTES.NOT_FOUND,
] as string[];

export const LIGHT_HEADER = [ROUTES.PLAY, ROUTES.INSIGHT_ARTICLE] as string[];

export const DISABLED_HEADER_LOGO = [ROUTES.HOME] as string[];

import { useRouter } from 'next/router';
import React, { FunctionComponent, memo, useEffect, useState } from 'react';

import LinkComp from 'components/LinkComp/LinkComp';
import RichText from 'components/RichText/RichText';
import SocialsPanel from 'components/SocialsPanel/SocialsPanel';
import Text from 'components/Text/Text';
import WeChatQrCode from 'components/WeChatQrCode/WeChatQrCode';
import {
  FooterDataProps,
  FooterTypes,
} from 'utils/sharedStrapiQueries/sharedTypes';
import { ColorNames } from 'utils/styles/theme';

import * as S from './Footer.styles';

export interface FooterProps {
  location: FooterTypes;
  address: string;
  contact: string;
  locationTextColor?: string;
  locationDefaultTextColors?: string;
  linksColor?: string;
  linksDefaultTextColors?: string;
  bottomText?: string;
  pageRoutes: FooterDataProps['pageRoutes'];
}

const defaultProps: Partial<FooterProps> = {
  location: {
    name: '',
    heading: '',
    telephoneNumber: '',
    email: '',
    bottomContactsInfo: [],
    links: [],
    copyright: '',
    socialsToastText: '',
    socials: [],
    socialsLinks: [],
    timeZone: 'Europe/London',
    coordinates: {
      office: {
        latitude: 0,
        longitude: 0,
      },
    },
  },
  address: '',
  contact: '',
  pageRoutes: [],
};

const Footer: FunctionComponent<FooterProps> = ({
  location,
  address,
  contact,
  locationTextColor,
  locationDefaultTextColors,
  linksColor,
  linksDefaultTextColors,
  bottomText,
  pageRoutes,
  ...rest
}) => {
  const [time, setTime] = useState<string>('00:00');
  const router = useRouter();

  useEffect(() => {
    const setCurrentTime = () =>
      setTime(
        new Date().toLocaleTimeString(router.locale, {
          timeZone: location.timeZone.replace(/1/, '/'),
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
        })
      );

    setCurrentTime();

    const refreshTime = setInterval(setCurrentTime, 30000);
    return () => clearInterval(refreshTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const isBottomText = !!bottomText?.length;
  const textColor = linksColor || linksDefaultTextColors;

  const topContent = (
    <S.TopContainer isBottomText={isBottomText}>
      <S.Location textColor={locationTextColor ?? locationDefaultTextColors}>
        <RichText
          as="h4"
          size="heading4"
          richText={location?.heading}
          defaultFormatting
        />
        <RichText as="time" size="heading4" richText={time} defaultFormatting />
      </S.Location>

      <S.Content>
        <S.Column textColor={textColor}>
          {pageRoutes?.map(({ name, href }, index) => (
            <LinkComp key={name + href + index} href={href}>
              {name}
            </LinkComp>
          ))}
        </S.Column>

        <S.Column>
          <Text
            as="h5"
            size="small"
            text={address}
            color={ColorNames.silverChalice}
          />
          {location.bottomContactsInfo.map(({ text }, index) =>
            text === '<br>' ? (
              <br />
            ) : (
              <Text key={text + index} size="small" text={text} />
            )
          )}
        </S.Column>

        <S.Column textColor={textColor}>
          <Text
            as="h5"
            size="small"
            text={contact}
            color={ColorNames.silverChalice}
          />
          <LinkComp href={`tel:${location?.telephoneNumber}`}>
            {location?.telephoneNumber}
          </LinkComp>
          <LinkComp href={`mailto:${location?.email}`}>
            {location?.email}
          </LinkComp>
        </S.Column>

        {!router.locale.includes('zh-CN') && (
          <S.Column textColor={textColor}>
            <SocialsPanel
              socials={{
                socials: location.socials,
                links: location.socialsLinks,
                toastText: location.socialsToastText,
              }}
            />
          </S.Column>
        )}

        <S.Column>
          <WeChatQrCode option={1} />
        </S.Column>
      </S.Content>

      {isBottomText && (
        <S.BottomTextWrapper>
          <RichText
            as="h4"
            size="heading4"
            richText={bottomText}
            defaultFormatting
          />
        </S.BottomTextWrapper>
      )}
    </S.TopContainer>
  );

  const bottomContent = (
    <S.BottomContainer>
      <S.LeftBottomSide>
        {location?.links.map(({ label, href, isExternal }, index) => (
          <LinkComp
            key={label + href + index}
            href={href}
            isExternal={isExternal}
          >
            {label}
          </LinkComp>
        ))}
      </S.LeftBottomSide>

      <Text
        as="h5"
        size="small"
        text={location?.copyright}
        color={ColorNames.silverChalice}
      />
    </S.BottomContainer>
  );

  return (
    <S.Wrapper {...rest}>
      {topContent}
      {bottomContent}
    </S.Wrapper>
  );
};

Footer.defaultProps = defaultProps;

export default memo(Footer);

export enum CMS_FUNCTIONS_TAGS_NAME {
  set = 'set',
  pink = 'pink',
  violet = 'violet',
  blue = 'blue',
  green = 'green',
}

export const OVERRIDE_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'];

export const CUSTOM_TAGS = [
  CMS_FUNCTIONS_TAGS_NAME.set,
  CMS_FUNCTIONS_TAGS_NAME.pink,
  CMS_FUNCTIONS_TAGS_NAME.violet,
  CMS_FUNCTIONS_TAGS_NAME.green,
  CMS_FUNCTIONS_TAGS_NAME.blue,
];

export const isStartWithCustomTag = (text, tag) => {
  const regex = new RegExp(`^<${tag}(\\s[^>]+)?>.*<${tag}>$`, 'i');
  const startsWithTagRegex = new RegExp(`^<${tag}(\\s[^>]+)?>`, 'i');
  return regex.test(text) || startsWithTagRegex.test(text);
};

export const checkCustomTags = text => {
  const usedTags = CUSTOM_TAGS.filter(tag => isStartWithCustomTag(text, tag));
  return usedTags.length;
};

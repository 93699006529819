import styled from 'styled-components';

interface WrapperProps {}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
`;

export const SocialContainer = styled.div`
  cursor: pointer;
`;

import { Pages } from 'utils/routes';

// Actually we are using only one animation
// import insightsAnimation from './lotties/DBP_Chairs.json';
// import workAnimation from './lotties/DBP_D.json';
// import joinAnimation from './lotties/DBP_Ripple.json';
import aboutAnimation from './lotties/DBP_Short.json';

export type AnimationsKeys =
  | Pages.work
  | Pages.about
  | Pages.join
  | Pages.insights;

export type AnimationDataTypes = Record<AnimationsKeys, any>;

export const animationData: AnimationDataTypes = {
  [Pages.work]: aboutAnimation,
  [Pages.about]: aboutAnimation,
  [Pages.join]: aboutAnimation,
  [Pages.insights]: aboutAnimation,
};

/* eslint-disable prettier/prettier */
import { keyframes } from 'styled-components';

export const spin = () => keyframes`
  0 { transform: rotate(0); }
  100% { transform: rotate(1turn); }
`;

export const pulse = (transform?: string) => keyframes`
  0% { opacity: 0; transform: ${transform ? `${transform} ` : ''}scale(0.5); }
  40%, 45% { opacity: 1; transform: ${
    transform ? `${transform} ` : ''
  }scale(1); }
  60%, 100% { opacity: 0; transform: ${
    transform ? `${transform} ` : ''
  }scale(0.95); }
`;

export interface OpacityMotionPropsProps {
  duration?: number;
  min?: number;
  max?: number;
  delay?: number;
}
export const opacityMotionProps = ({
  duration = 0.6, min = 0, delay = 0, max = 1,
}:OpacityMotionPropsProps) => ({
  initial: { opacity: min },
  animate: { opacity: max },
  exit: { opacity: min },
  transition: { duration: duration, delay: delay },
});

export const pageMotionProps = {
  initial: { opacity: 0, x: '50rem' },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: '-50rem' },
  transition: { duration: 0.6 },
};

export const tilesMotionProps = {
  whileHover: 'hover',
  transition: {
    mass: 0.5,
    delay: 0.15,
    duration: 0.6,
    easeIn: [0.25, 0.1, 0.25, 1],
  },
};

export const initialLoadMotionProps = {
  initial: { opacity: 0, y: 100 },
  animate: { opacity: 1, y: 0 },
  transition: {
    mass: 0.5,
    delay: 0.15,
    duration: 0.6,
    easeIn: [0.25, 0.1, 0.25, 1],
  },
};

export const overlayMotionProps = {
  initial: { x: '100%' },
  animate: {
    x: 0,
    transition: { delay: 0.4, duration: 0.6, easeIn: [0.25, 0.1, 0.25, 1] },
  },
  exit: { x: '100%' },
  transition: {
    duration: 0.6,
    easeIn: [0.25, 0.1, 0.25, 1],
  },
};

export const overlayOpacityProps = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: 0.3, easeIn: [0.25, 0.1, 0.25, 1] },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      easeIn: [0.25, 0.1, 0.25, 1],
    },
  },
};

export const toastMotionProps = {
  animate: {
    opacity: 1,
    scale: [1, 1.2, 1],
    transition: { duration: 0.6 },
  },
  exit: { opacity: 0, transition: { duration: 0.4 } },
};

export const openFilterMotionProps = ref => ({
  initial: { height: ref?.current?.style.height || 52 },
  animate: {
    height: 'auto',
  },
  transition: { duration: 0.4, ease: [0.53, 0.22, 0.31, 1] },
});

export const filtersMotionProps = index => ({
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.8,
      delay: index * 0.13,
    },
  },
});

export const getBannerMotionProps = (
  from: 'top' | 'bottom',
  duration?: number
) => ({
  initial: { opacity: 0, y: `${(from === 'top' ? -1 : 1) * 50}%` },
  animate: { opacity: 1, y: '0%' },
  exit: { opacity: 0, y: `${(from === 'top' ? -1 : 1) * 50}%` },
  transition: { duration: duration || 0.6 },
});

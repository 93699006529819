import { useMotionValue } from 'framer-motion';
import uniqueId from 'lodash/uniqueId';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { getRadioPosition } from './RadioBlock.utils';

import * as S from './RadioBlock.styles';

export interface IRadioButton {
  label: React.ReactNode;
  value: string;
}

export interface RadioBlockProps {
  hoverIndicator: React.ReactNode;
  radioButtons: IRadioButton[];
  onSelect: (selected: string) => void;
  defaultValue: string;
}

const RadioBlock: React.FunctionComponent<RadioBlockProps> = ({
  radioButtons,
  onSelect,
  hoverIndicator,
  defaultValue,
  ...rest
}) => {
  const uniqueName = useMemo(() => uniqueId('radio_'), []);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [selected, setSelected] = useState<string>(defaultValue);
  const [hovered, setHovered] = useState<string>(defaultValue);

  const y = useMotionValue(0);

  useEffect(() => {
    y.set(getRadioPosition(radioButtons, wrapperRef, hovered));
  }, [hovered, y, radioButtons]);

  useEffect(() => {
    if (selected !== defaultValue) onSelect(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const indicatorColumn = (
    <S.IndicatorWrapper rows={radioButtons.length}>
      <S.Indicator style={{ y }}>{hoverIndicator}</S.Indicator>
    </S.IndicatorWrapper>
  );

  const radiosColumn = (
    <S.Radios
      rows={radioButtons.length}
      ref={wrapperRef}
      onMouseLeave={() => setHovered(selected)}
      {...rest}
    >
      {radioButtons.map(({ label, value }) => (
        <S.RadioWrapper key={uniqueId()} onMouseEnter={() => setHovered(value)}>
          <S.RadioInput
            id={value}
            name={uniqueName}
            value={value}
            type="radio"
            onChange={e => setSelected(e.target.value)}
            checked={value === selected}
          />
          <label htmlFor={value}>{label}</label>
        </S.RadioWrapper>
      ))}
    </S.Radios>
  );

  return (
    <S.RadioBlockWrapper>
      {indicatorColumn}

      {radiosColumn}
    </S.RadioBlockWrapper>
  );
};

export default RadioBlock;

import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';

import Text from 'components/Text/Text';
import { useCopyStore } from 'store';
import { handleMouseEnter, handleMouseLeave } from 'utils/cursorGlobal';

import * as S from './Button.styles';

export interface ButtonProps {
  label: string;
  href?: string;
  transparent?: boolean;
  target?: string;
  onClick?: () => void;
}

const defaultProps: Partial<ButtonProps> = {
  label: 'Example',
  target: '_self',
};

const Button: FunctionComponent<ButtonProps> = ({
  label,
  href,
  onClick,
  transparent,
  target,
  ...rest
}) => {
  const { globalSettings } = useCopyStore(({ copy }) => copy);
  const router = useRouter();

  const LinkElement = ({ children }) =>
    href ? (
      <Link href={href} passHref>
        <S.Anchor rel="noreferrer" target={target} aria-label={label}>
          {children}
        </S.Anchor>
      </Link>
    ) : (
      <>{children}</>
    );

  return (
    <LinkElement>
      <S.Wrapper
        tabIndex={0}
        aria-label={label}
        role="button"
        onClick={() => (href ? router.push(href) : onClick && onClick())}
        transparent={transparent}
        hoverColor={
          globalSettings?.buttonsHoverColor ||
          globalSettings?.buttonsHoverDefaultColors
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...rest}
      >
        <Text as="span" size="button" text={label} />
      </S.Wrapper>
    </LinkElement>
  );
};

Button.defaultProps = defaultProps;

export default Button;

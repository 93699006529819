import { times } from 'lodash';

import { ROUTES } from './routes';
import { AspectRatio, PagesTypes } from './sharedStrapiQueries/sharedTypes';

export const aspectRatioConverter = (aspectRatio: AspectRatio) => {
  switch (aspectRatio) {
    case '_1x1':
      return '1/1';

    case '_2x3':
      return '2/3';

    case '_4x5':
      return '4/5';

    case '_6x4':
      return '6/4';

    case '_16x9':
      return '16/9';

    case 'default':
      return 'auto';

    default:
      return 'auto';
  }
};

export const redirectToPageUrl = (
  redirectTo: string,
  { awardPage, eventPage, newsArticle, caseStudy, insightArticle }: PagesTypes
) => {
  switch (redirectTo) {
    case 'AwardPage':
      return `/awards/${awardPage?.slug}`;
    case 'EventPage':
      return `/events/${eventPage?.slug}`;
    case 'NewsArticlePage':
      return `/news/${newsArticle?.slug}`;
    case 'CaseStudyPage':
      return `${ROUTES.WORK}/${caseStudy?.slug}`;
    case 'InsightArticle':
      return `/insights/${insightArticle?.slug}`;
    default:
      return '';
  }
};

export const textArrayLength = (arr: string[]) => {
  if (!arr.length) return 0;
  let length = 0;

  arr.forEach(text => (length += text.split(' ').length));

  return length;
};

export class Timer {
  private paused = false;
  private startTime = Date.now();

  private currentTime = 0;

  constructor(private callback: () => void, private delay: number) {
    this.frame();
  }

  pause = () => {
    this.paused = true;
  };

  resume = () => {
    this.paused = false;
    this.frame();
  };

  frame = () => {
    if (Date.now() < this.startTime + this.delay) {
      if (this.paused) return;

      this.currentTime = this.startTime + this.delay - Date.now();
      window.requestAnimationFrame(this.frame);
    } else {
      this.callback();
    }
  };

  getCurrentTime = () => {
    return this.currentTime;
  };
}

export const keyboardFocus = (e: KeyboardEvent) => {
  const documentClassList = document.documentElement.classList;
  const className = 'accessibility-focus';

  if (e.key === 'Tab') documentClassList.add(className);

  if (documentClassList.contains(className))
    documentClassList.remove(className);
};

export const groupByModulo = (array: any[], value: number) => {
  const groups = times(value, () => []);

  array.forEach((element, index) => {
    groups[index % value].push(element);
  });

  return groups;
};

export const calculateScrollPercentage = () => {
  const scrollPosition = window.scrollY;
  const totalHeight = document.body.scrollHeight - window.innerHeight;
  const scrollPercentage = (scrollPosition / totalHeight) * 100;
  return scrollPercentage.toFixed(2);
};

export const isOverElement = (element1, element2, threshold = true) => {
  const isThreshold = threshold ? 60 : 0;
  const element1Rect = element1.getBoundingClientRect();
  const element2Rect = element2.getBoundingClientRect();
  const element1Top = element1Rect.top + window.scrollY;
  const element1Bottom = element1Rect.bottom + window.scrollY;
  const element2Top = element2Rect.top + window.scrollY - isThreshold;
  const element2Bottom = element2Top + element2Rect.height + 80;

  return element1Top >= element2Top && element1Bottom <= element2Bottom;
};

export const stringToBoolean = (str = 'true') => str.toLowerCase() === 'true';

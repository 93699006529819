import React, { FunctionComponent } from 'react';

import Text from 'components/Text/Text';
import { toastMotionProps } from 'utils/styles/animations';
import { ColorNames } from 'utils/styles/theme';

import * as S from './Toast.styles';

export interface ToastProps {
  text: string;
}

const defaultProps: Partial<ToastProps> = {
  text: 'Toast',
};

const Toast: FunctionComponent<ToastProps> = ({ text, ...rest }) => {
  return (
    <S.ToastWrapper {...rest}>
      <S.Container {...toastMotionProps}>
        <Text as="span" size="small" text={text} color={ColorNames.black} />
      </S.Container>
    </S.ToastWrapper>
  );
};

Toast.defaultProps = defaultProps;

export default Toast;

import { useCycle, useMotionValue, useSpring } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { isTouchDevice } from 'u9/utils/platform';
import {
  currentEvent,
  cursorLabel,
  EVENT_NAME,
  setCursorLabel,
  triggerDefaultCursor,
} from 'utils/cursorController';

import {
  CursorVariants,
  CursorWrapperVariants,
  LabelVariants,
} from './Cursor.animations';

import * as S from './Cursor.styles';

export interface CursorProps {}

const Cursor: React.FunctionComponent<CursorProps> = ({ ...rest }) => {
  const [isInViewport, setIsInViewport] = useCycle(false, true);
  const [cursorVariant, setCursorVariant] = useState<string>('default');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [label, setLabel] = useState('');
  const router = useRouter();

  const cursorX = useMotionValue(0);
  const cursorY = useMotionValue(0);

  const springSettings = { damping: 10, mass: 0.25, stiffness: 100 };

  const springX = useSpring(cursorX, springSettings);
  const springY = useSpring(cursorY, springSettings);

  const setMixBlendMode =
    cursorVariant === 'scaleUp' || cursorVariant === 'default'
      ? 'difference'
      : 'initial';

  const setWhiteColorLabel =
    cursorVariant === 'dark' ||
    cursorVariant === 'drag' ||
    cursorVariant === 'moveMe' ||
    cursorVariant === 'moveMeGrey';

  useEffectOnce(() => {
    const handleMouseMove = (e: any) => {
      setIsInViewport(1);
      cursorX.set(e.clientX);
      cursorY.set(e.clientY);
    };

    const handleMouseOut = () => setIsInViewport(0);
    const handleCursorChange = () => {
      setLabel(cursorLabel);
      setCursorVariant(currentEvent);
    };

    window.addEventListener('mouseover', handleMouseMove, { passive: true });
    window.addEventListener('mousemove', handleMouseMove, { passive: true });
    window.addEventListener('mouseout', handleMouseOut);

    window.addEventListener(EVENT_NAME, handleCursorChange);

    return function cleanUp() {
      window.removeEventListener('mouseover', handleMouseMove);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseout', handleMouseOut);
      window.removeEventListener(EVENT_NAME, handleCursorChange);
    };
  });

  useEffect(() => {
    triggerDefaultCursor();
    setCursorLabel('');
  }, [router.pathname, router.route]);

  if (isTouchDevice()) return null;

  return (
    <S.CursorWrapper
      initial="initial"
      animate={isInViewport ? 'visible' : 'hidden'}
      variants={CursorWrapperVariants}
      style={{
        // remove spring when has label
        x: cursorLabel ? cursorX : springX,
        y: cursorLabel ? cursorY : springY,
        mixBlendMode: setMixBlendMode,
      }}
      {...rest}
    >
      <S.Pointer
        variants={CursorVariants}
        animate={
          cursorVariant.includes('moveMe')
            ? cursorVariant === 'moveMe'
              ? 'moveMe'
              : 'moveMeGrey'
            : 'disable'
        }
        dragCursor={cursorVariant === 'drag'}
      />
      {cursorLabel &&
        cursorLabel.length > 0 &&
        cursorVariant.includes('moveMe') && (
          <S.Label
            variants={LabelVariants}
            initial="hidden"
            animate="visible"
            isWhite={setWhiteColorLabel}
          >
            {cursorLabel}
          </S.Label>
        )}
    </S.CursorWrapper>
  );
};
export default Cursor;

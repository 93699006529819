import { motion } from 'framer-motion';
import styled from 'styled-components';

import { setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

type Keys = keyof typeof colors;

interface WrapperProps {
  backgroundColor: typeof colors[Keys];
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 100%;
  min-height: ${setVh(100)};
  position: relative;
  background-color: ${({ backgroundColor }) => colors[backgroundColor]};
`;

export const Content = styled(motion.main)``;

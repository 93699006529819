export enum ColorNames {
  white = 'white',
  black = 'black',
  poppingPink = 'poppingPink',
  ultraViolet = 'ultraViolet',
  electricBlue = 'electricBlue',
  chargedGreen = 'chargedGreen',
  lighterMineShaft = 'lighterMineShaft',
  brightTurquoise = 'brightTurquoise',
  silverChalice = 'silverChalice',
  codGrayDarker = 'codGrayDarker',
  codGrayHover = 'codGrayHover',
  codGrayLight = 'codGrayLight',
  blueRibbon = 'blueRibbon',
  redRibbon = 'redRibbon',
  alabaster = 'alabaster',
  dustyGray = 'dustyGray',
  mineShaft = 'mineShaft',
  cinnabar = 'cinnabar',
  concrete = 'concrete',
  tundora = 'tundora',
  gallery = 'gallery',
  riptide = 'riptide',
  bayLeaf = 'bayLeaf',
  mercury = 'mercury',
  codGray = 'codGray',
  gray = 'gray',

  twine = 'twine',
  alto = 'alto',
  red = 'red',
  brightGray = 'brightGray',
}

export enum FontNames {
  sequelSans = 'sequelSans',
  sequelSansVFC1 = 'sequelSansVFC1',
  sequelSansVFB1 = 'sequelSansVFB1',
  notoSans = 'notoSans',
  oppoSans = 'oppoSans',
  menlo = 'menlo',
}

export const validFontCategories = [
  'displayHero',
  'display',
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'heading5',
  'body',
  'smallBody',
  'button',
  'small',
  'button',
  'filter',
] as const;

interface ResponsiveValues {
  mobile?: number;
  tablet?: number;
  desktop?: number;
}

interface FontCategory {
  fontFamily?: FontNames | Record<string, FontNames>;
  fontSize: ResponsiveValues;
  lineHeight: ResponsiveValues;
  fontWeight: number;
  letterSpacing?: number;
  wordSpacing?: number;
  fontStretch?: 'expanded' | 'normal';
  textDecoration?: 'underline' | 'none';
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'full-size-kana'
    | 'full-width'
    | 'lowercase';
  fontStyle?: 'normal' | 'italic' | 'oblique' | 'inherit' | 'initial' | 'unset';
}

export interface Theme {
  colors: Record<ColorNames, string>;
  fonts: {
    face: Record<FontNames, string>;
    scale: Record<typeof validFontCategories[number], FontCategory>;
  };
  layout: typeof layout;
}

// "Name that Color" names
export const colors: Theme['colors'] = {
  [ColorNames.white]: '#FFFFFF',
  [ColorNames.black]: '#000000',

  [ColorNames.poppingPink]: '#FF1A9A',
  [ColorNames.ultraViolet]: '#C31AFF',
  [ColorNames.electricBlue]: '#0094FF',
  [ColorNames.chargedGreen]: '#9CFF00',
  [ColorNames.tundora]: '#4B4B4B',
  [ColorNames.gray]: '#8F8F8F',

  [ColorNames.lighterMineShaft]: '#2D2D2D',
  [ColorNames.brightTurquoise]: '#09FEC2',
  [ColorNames.silverChalice]: '#B1B1B1',
  [ColorNames.codGrayDarker]: '#191919',
  [ColorNames.codGrayHover]: '#2A2A2A',
  [ColorNames.codGrayLight]: '#1D1D1D',
  [ColorNames.blueRibbon]: '#0070F3',
  [ColorNames.redRibbon]: '#F40732',
  [ColorNames.alabaster]: '#FAFAFA',
  [ColorNames.dustyGray]: '#949494',
  [ColorNames.mineShaft]: '#1F1F1F',
  [ColorNames.cinnabar]: '#E3474C',
  [ColorNames.concrete]: '#F2F2F2',
  [ColorNames.gallery]: '#EAEAEA',
  [ColorNames.riptide]: '#8CE3D6',
  [ColorNames.bayLeaf]: '#76AC7D',
  [ColorNames.mercury]: '#E3E3E3',
  [ColorNames.codGray]: '#1A1A1A',
  [ColorNames.twine]: '#C69E6F',
  [ColorNames.alto]: '#DDD',
  [ColorNames.red]: '#F00',
  [ColorNames.brightGray]: '#D9D9D9',
} as const;

export const fonts: Theme['fonts'] = {
  face: {
    [FontNames.sequelSans]:
      // eslint-disable-next-line quotes
      "'Sequel Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    [FontNames.sequelSansVFC1]:
      // eslint-disable-next-line quotes
      "'Sequel Sans VFC1', 'Helvetica Neue', Helvetica, Arial, sans-serif",

    [FontNames.sequelSansVFB1]:
      // eslint-disable-next-line quotes
      "'Sequel Sans VFB1', 'Helvetica Neue', Helvetica, Arial, sans-serif",

    [FontNames.notoSans]:
      // eslint-disable-next-line quotes
      "'Noto Sans SC', 'Helvetica Neue', Helvetica, Arial, sans-serif",

    [FontNames.oppoSans]:
      // eslint-disable-next-line quotes
      "'OPPO Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",

    [FontNames.menlo]:
      // eslint-disable-next-line quotes, prettier/prettier
      'Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace',
  },
  scale: {
    // Based on Material design typography scale, adjust as needed
    // fontSize: px, letterSpacing: em
    // fontFamily: { [localeCode]: localeSpecificFont, default: defaultFont }
    displayHero: {
      fontFamily: {
        'zh-CN': FontNames.oppoSans,
        default: FontNames.sequelSans,
      },
      fontSize: { tablet: 110, mobile: 65 },
      lineHeight: { tablet: 1.0909, mobile: 1.0909 },
      fontWeight: 150,
    },
    display: {
      fontFamily: {
        'zh-CN': FontNames.oppoSans,
        default: FontNames.sequelSans,
      },
      fontSize: { tablet: 96, mobile: 51 },
      lineHeight: { tablet: 0.8, mobile: 0.8 },
      fontWeight: 150,
    },
    heading1: {
      fontFamily: {
        'zh-CN': FontNames.oppoSans,
        default: FontNames.sequelSans,
      },
      fontSize: { tablet: 68, mobile: 44 },
      lineHeight: { tablet: 1, mobile: 1.091 },
      fontWeight: 135,
    },
    heading2: {
      fontFamily: {
        'zh-CN': FontNames.oppoSans,
        default: FontNames.sequelSans,
      },
      fontSize: { tablet: 48, mobile: 35 },
      lineHeight: { tablet: 1.0832, mobile: 1.0856 },
      fontWeight: 135,
    },
    heading3: {
      fontFamily: {
        'zh-CN': FontNames.oppoSans,
        default: FontNames.sequelSans,
      },
      fontSize: { tablet: 40, mobile: 28 },
      lineHeight: { tablet: 1.125, mobile: 1.143 },
      fontWeight: 135,
    },
    heading4: {
      fontFamily: {
        'zh-CN': FontNames.oppoSans,
        default: FontNames.sequelSans,
      },
      fontSize: { tablet: 30, mobile: 22 },
      lineHeight: { tablet: 1.2, mobile: 1.136 },
      fontWeight: 135,
    },
    heading5: {
      fontFamily: {
        'zh-CN': FontNames.oppoSans,
        default: FontNames.sequelSans,
      },
      // fontFamily: { default: FontNames.sequelSansVFC1 },
      fontSize: { tablet: 22, mobile: 18 },
      lineHeight: { tablet: 1.318, mobile: 1.389 },
      fontWeight: 135,
    },
    body: {
      fontFamily: {
        'zh-CN': FontNames.oppoSans,
        default: FontNames.sequelSans,
      },
      fontSize: { tablet: 22, mobile: 17 },
      lineHeight: { tablet: 1.318, mobile: 1.412 },
      fontWeight: 110,
      letterSpacing: -0.01,
    },
    smallBody: {
      fontFamily: {
        'zh-CN': FontNames.oppoSans,
        default: FontNames.sequelSans,
      },
      fontSize: { tablet: 18, mobile: 15 },
      lineHeight: { tablet: 1.389, mobile: 1.5328 },
      fontWeight: 110,
    },
    small: {
      fontFamily: {
        'zh-CN': FontNames.oppoSans,
        default: FontNames.sequelSans,
      },
      fontSize: { tablet: 15, mobile: 13 },
      lineHeight: { tablet: 1.5328, mobile: 1.538 },
      fontWeight: 110,
    },
    button: {
      // fontFamily: { default: FontNames.sequelSansVFC1 },
      fontFamily: {
        'zh-CN': FontNames.oppoSans,
        default: FontNames.sequelSans,
      },
      fontSize: { tablet: 18, mobile: 15 },
      lineHeight: { tablet: 1.222, mobile: 1.467 },
      fontWeight: 135,
    },
    filter: {
      // fontFamily: { default: FontNames.sequelSansVFC1 },
      fontFamily: {
        'zh-CN': FontNames.oppoSans,
        default: FontNames.sequelSans,
      },
      fontSize: { tablet: 15, mobile: 13 },
      lineHeight: { tablet: 1.467, mobile: 1.538 },
      fontWeight: 135,
    },
  },
} as const;

// Comment unit where applicable
export const layout = {
  borderRadius: 40, // rem
  lineOpacity: 0.1,
  zIndex: {
    overContent: 1,
    filter: 10,
    menuModal: 100,
    navbar: 1000,
    overlay: 10000,
    nonFunctionals: 100000,
  },
  columns: {
    min: 2,
    threeFourth: 3,
    fourThirds: 4,
    half: 6,
    max: 12,
  },
} as const;

const theme: Theme = {
  colors,
  fonts,
  layout,
} as const;

export default theme;

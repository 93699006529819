import {
  setCursorLabel,
  triggerDefaultCursor,
  triggerScaleUpCursor,
} from './cursorController';

export const handleMouseEnter = () => {
  triggerScaleUpCursor();
};

export const handleMouseLeave = () => {
  setCursorLabel('');
  triggerDefaultCursor();
};

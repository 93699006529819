import { useEffect, useState } from 'react';

type UseDisableScrollOutput = [boolean, (locked: boolean) => void];

const useDisableScroll = (
  id = '__next',
  initial = false
): UseDisableScrollOutput => {
  const [disabled, setDisabled] = useState<boolean>(initial);

  useEffect(() => {
    const selector = document.querySelector<HTMLElement>(`#${id}`);

    if (disabled) {
      selector.style.height = '100vh';
      selector.style.overflow = 'hidden';
    } else {
      selector.style.height = '';
      selector.style.overflow = '';
    }

    return () => {
      selector.style.height = '';
      selector.style.overflow = '';
    };
  }, [disabled]);

  return [disabled, setDisabled];
};

export default useDisableScroll;

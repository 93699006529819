import { CURSOR_STATE } from 'utils/cursorController';
import { colors } from 'utils/styles/theme';

import { getScaleValue } from './Cursor.utils';

export const CursorWrapperVariants = {
  hidden: { scale: 0, opacity: 0 },
  visible: { scale: 1, opacity: 1, transition: { duration: 0.3 } },
};

export const CursorVariants = {
  [CURSOR_STATE.default]: {
    scale: getScaleValue(22),
    x: '-50%',
    y: '-50%',
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 0.6,
      delay: 0.2,
    },
    backgroundColor: colors.white,
  },
  [CURSOR_STATE.disable]: {
    opacity: 0,
    scale: 0,
    x: '-50%',
    y: '-50%',
    transition: {
      duration: 0.3,
    },
  },
  [CURSOR_STATE.light]: {
    scale: 1,
    x: '-50%',
    y: '-50%',
    backgroundColor: colors.white,
  },
  [CURSOR_STATE.dark]: {
    scale: 1,
    x: '-50%',
    y: '-50%',
    backgroundColor: colors.black,
  },
  [CURSOR_STATE.scaleUp]: {
    scale: getScaleValue(54),
    x: '-50%',
    y: '-50%',
    backgroundColor: colors.white,
  },
  [CURSOR_STATE.drag]: {
    scale: 1,
    x: '-50%',
    y: '-50%',
    backgroundColor: colors.black,
  },
  [CURSOR_STATE.moveMe]: {
    scale: 0.6,
    x: '-50%',
    y: '-50%',
    backgroundColor: colors.black + '33',
  },
  [CURSOR_STATE.moveMeGrey]: {
    scale: 0.6,
    x: '-50%',
    y: '-50%',
    backgroundColor: colors.white + '33',
  },
};

export const LabelVariants = {
  hidden: { x: '-50%', y: '-50%', opacity: 0 },
  visible: { x: '-50%', y: '-50%', opacity: 1, transition: { delay: 0.1 } },
};

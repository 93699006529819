import { AnimatePresence, useAnimation } from 'framer-motion';
import uniqueId from 'lodash/uniqueId';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { ANIMATED_LOGO_PAGES } from 'components/Header/Header.constants';
import LinkComp from 'components/LinkComp/LinkComp';
import { IRadioButton } from 'components/RadioBlock/RadioBlock';
import Search from 'components/Search/Search';
import Text from 'components/Text/Text';
import Toast from 'components/Toast/Toast';
import WeChatQrCode from 'components/WeChatQrCode/WeChatQrCode';
import useClipboard from 'hooks/useClipboard';
import useDisableScroll from 'hooks/useDisableScroll';
import { useCopyStore, useGlobalStore } from 'store';
import { handleLanguage, languages } from 'utils/cms/languages';
import { handleMouseLeave } from 'utils/cursorGlobal';
import { ROUTES } from 'utils/routes';
import { FooterTypes } from 'utils/sharedStrapiQueries/sharedTypes';
import { ColorNames } from 'utils/styles/theme';

import * as A from './Menu.animations';

import * as S from './Menu.styles';

export interface MenuProps {
  location: FooterTypes;
}

const langSelectors: IRadioButton[] = languages.map(({ name, shortName }) => ({
  label: <S.LanguageLabel text={name} size="heading4" />,
  value: shortName,
}));

const Menu: FunctionComponent<MenuProps> = ({ location, ...rest }) => {
  const { copy } = useCopyStore();
  const router = useRouter();
  const {
    setHeaderText,
    isMenuOpen,
    setIsMenuOpen,
    setIsCopyLogo,
    isSearchOverlayOpen,
    setIsSearchOverlayOpen,
  } = useGlobalStore();

  const [showSearch, setShowSearch] = useState(false);
  const [searchButtonPosition, setSearchButtonPosition] = useState('0');
  const [, setLocked] = useDisableScroll('__next', true);

  const { isCopied, copyToClipboard } = useClipboard();
  const searchButtonAnimation = useAnimation();
  const menuItemsAnimation = useAnimation();

  const isBackgroundFromCMS = Boolean(
    copy.navbar?.menuBackgroundColor || copy.navbar?.menuBackgroundDefaultColors
  );

  const forceSearchHide = () => {
    setSearchButtonPosition('0');
    setShowSearch(false);
  };

  const hideMenu = () => {
    forceSearchHide();
    setLocked(false);
  };

  useEffect(() => {
    if (isSearchOverlayOpen) {
      animateSearchIn();
      setIsMenuOpen(true);
    }
  }, [isSearchOverlayOpen]);

  // TODO: This cause issue scroll to top when we open Menu
  useEffect(() => {
    isMenuOpen ? setLocked(true) : hideMenu();
  }, [isMenuOpen]);

  // Hover interactions requires preventing unnecessary rerenders
  const mainNavigation = useMemo(
    () => (
      <S.NavList animate={menuItemsAnimation}>
        {copy.navbar?.navLinks?.map(({ label, href, headerText }) => (
          <li
            key={uniqueId()}
            onMouseEnter={() => (
              setHeaderText({ default: headerText }), setIsCopyLogo(true)
            )}
            onMouseLeave={() => (
              setHeaderText({
                default: copy.navbar.navLinks.find(
                  ({ href }) => href === router.pathname
                )?.headerText,
              }),
              [...ANIMATED_LOGO_PAGES, ROUTES.CONTACT].includes(
                router.pathname
              ) && setIsCopyLogo(false)
            )}
            onClick={() => setIsMenuOpen(false)}
          >
            <LinkComp href={href}>{label}</LinkComp>
          </li>
        ))}
      </S.NavList>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [copy]
  );

  const mailList = (
    <S.MailList onClick={(e: any) => copyToClipboard(e.target.innerText)}>
      {copy.navbar?.emailTile?.map(({ title, email }) => (
        <S.MailListItem key={uniqueId()}>
          <Text
            as="h5"
            size="small"
            text={title}
            color={ColorNames.silverChalice}
          />
          <Text as="span" size="small" text={email} />
        </S.MailListItem>
      ))}
    </S.MailList>
  );

  const animateSearchIn = () => {
    menuItemsAnimation
      .start({ opacity: 0, transition: { duration: 0.5 } })
      .then();

    searchButtonAnimation
      .start({
        y: '-100%',
        transition: { duration: 0.5 },
      })
      .then(() => {
        menuItemsAnimation.set({
          display: 'none',
        });
        searchButtonAnimation.set({
          visibility: 'hidden',
        });

        setSearchButtonPosition('-100%');
        setShowSearch(true);
      });
  };

  const animateSearchOut = () => {
    setShowSearch(false);

    if (router.asPath === ROUTES.HOME) {
      setIsMenuOpen(false);
      setIsSearchOverlayOpen(false);
    } else {
      menuItemsAnimation.set({ display: null });
      menuItemsAnimation.start({ opacity: 1 }).then();

      searchButtonAnimation.set({ visibility: 'visible' });
      searchButtonAnimation
        .start({
          y: 0,
          transition: { duration: 0.5 },
        })
        .then();
    }
  };

  const searchButton = (
    <S.SearchButtonWrapper
      animate={searchButtonAnimation}
      initial={{ y: searchButtonPosition }}
    >
      <S.SearchButton onClick={animateSearchIn}>
        {copy.navbar?.searchInputText || ''}
      </S.SearchButton>
    </S.SearchButtonWrapper>
  );

  const info = (
    <S.Info animate={menuItemsAnimation}>
      <S.RadioBlockWrapper>
        <S.RadioBlock
          hoverIndicator={
            <S.LanguageIndicator $isBackgroundFromCMS={isBackgroundFromCMS} />
          }
          radioButtons={langSelectors}
          onSelect={s => handleLanguage(router, s)}
          defaultValue={router?.locale}
        />
      </S.RadioBlockWrapper>

      <WeChatQrCode option={0} />

      {mailList}
      {!router.locale.includes('zh-CN') && (
        <S.SocialsPanel
          socials={{
            socials: location.socials,
            links: location.socialsLinks,
            toastText: location.socialsToastText,
          }}
        />
      )}
    </S.Info>
  );

  const mainMenu = (
    <S.MenuContent>
      <S.Nav>
        {searchButton}

        {mainNavigation}
      </S.Nav>

      {info}
    </S.MenuContent>
  );

  const search = (
    <S.SearchWrapper isBackgroundFromCMS={isBackgroundFromCMS}>
      <Search
        placeholder={copy.navbar?.searchInputText || ''}
        onCancel={animateSearchOut}
      />
    </S.SearchWrapper>
  );

  return (
    <AnimatePresence>
      {isMenuOpen && (
        <S.Wrapper
          role="modal"
          initial="closed"
          animate="open"
          exit="closed"
          variants={A.sidebarAnimation}
          onMouseEnter={handleMouseLeave}
          backgroundColor={
            copy.navbar?.menuBackgroundColor ||
            copy.navbar?.menuBackgroundDefaultColors
          }
          {...rest}
        >
          <S.Container>
            {mainMenu}

            {showSearch && search}
          </S.Container>
          {isCopied && <Toast text="✓" />}
        </S.Wrapper>
      )}
    </AnimatePresence>
  );
};

export default Menu;

import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useState } from 'react';

import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import useDisableScroll2 from 'hooks/useDisableScroll2';
import { opacityMotionProps } from 'utils/styles/animations';

import * as S from './RedirectPopup.styles';

export type RedirectPopupData = {
  headingText: string;
  button1Label: string;
  button1Href: string;
  continueButtonLabel: string;
  query: string;
  isVisibleOnLoad: boolean;
  fixedVisibility: boolean;
};

export interface RedirectPopupProps {
  redirectContent: RedirectPopupData[];
}

const defaultProps: Partial<RedirectPopupProps> = {
  redirectContent: [],
};

const RedirectPopup: FunctionComponent<RedirectPopupProps> = ({
  redirectContent,
  ...rest
}) => {
  const [data, setData] = useState<RedirectPopupData>(null);
  const [visible, setIsVisible] = useState<boolean>(false);
  const router = useRouter();

  useDisableScroll2(visible);

  useEffect(() => {
    const filterData = redirectContent?.find(data =>
      data?.query === null ? true : router.asPath.includes(data.query)
    );

    if (!filterData) return setIsVisible(false);
    setIsVisible(filterData?.isVisibleOnLoad);
    setData(filterData);
  }, [redirectContent]);

  useEffect(() => {
    if (
      localStorage.getItem(
        `redirectionPopupVisibility_${data?.query ?? ''}`
      ) === '0'
    ) {
      data?.fixedVisibility &&
        localStorage.getItem('redirectionPopupVisibility__hidden') === '1' &&
        setIsVisible(false);

      !data?.fixedVisibility && setIsVisible(false);
    }
  }, [data]);

  if (!visible || !data) return null;

  const button1Condition = data?.button1Label || data?.button1Href;

  return (
    <AnimatePresence initial mode="wait">
      <S.Wrapper {...opacityMotionProps({ duration: 0.4 })} {...rest}>
        <S.Container>
          <Text as="h3" size="heading3" text={data?.headingText} />

          <S.ButtonsWrapper>
            {button1Condition && (
              <Button
                label={data.button1Label}
                href={data.button1Href}
                target="_blank"
              />
            )}
            <S.ContinueButton
              label={data?.continueButtonLabel}
              onClick={() => (
                setIsVisible(false),
                localStorage.setItem(
                  `redirectionPopupVisibility_${data.query ?? ''}`,
                  '0'
                ),
                data.fixedVisibility &&
                  localStorage.setItem(
                    'redirectionPopupVisibility__hidden',
                    '1'
                  )
              )}
            />
          </S.ButtonsWrapper>
        </S.Container>
      </S.Wrapper>
    </AnimatePresence>
  );
};

RedirectPopup.defaultProps = defaultProps;

export default RedirectPopup;

import { motion } from 'framer-motion';
import styled from 'styled-components';

import Button from 'components/Button/Button';
import { setVh } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { layout } from 'utils/styles/theme';

interface WrapperProps {}

export const Wrapper = styled(motion.section)<WrapperProps>`
  min-width: 100vw;
  height: ${setVh(100)};

  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(13px);

  position: fixed;
  z-index: ${layout.zIndex.nonFunctionals};

  display: flex;
  align-items: flex-end;

  ${mediaDesktop(`
    align-items: center;
  `)}
`;

export const Container = styled.div`
  width: 100%;
  height: 92%;
  display: grid;
  grid-template-columns: [out-left] 16px [main-section] auto [out-right] 16px;

  h3 {
    align-self: self-end;
    grid-column: main-section / out-right;
  }

  ${mediaDesktop(`
    height: unset;
    grid-template-columns: [out-left] 21.905% [main-section] auto [out-right] 21.905%;

    h3 {
      margin-bottom: 40px;
    }
  `)}
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-column: main-section / out-right;

  margin: auto 0 40px;

  & > button,
  & > a,
  a > button {
    width: 100%;
    justify-content: center;
  }

  ${mediaDesktop(`
    display: flex;
    margin: 0;

    & > button, a > button {
      width: auto;
      justify-content: unset;
      margin-right: 16px;
    }

    & > a {
      width: auto;
      justify-content: unset;
    }
  `)}
`;

export const ContinueButton = styled(Button)`
  background: rgba(242, 242, 242, 0.2);
`;

import React from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';

import { layout } from 'utils/styles/theme';

export const Overlay = styled.div`
  position: relative;
  z-index: ${layout.zIndex.overlay};
`;
export const OVERLAY_ID = Object.assign(Overlay).styledComponentId;

const useOverlayPortal = () => {
  const render = (children: React.ReactNode) =>
    ReactDom.createPortal(children, document.querySelector(`.${OVERLAY_ID}`));
  return render;
};

export default useOverlayPortal;

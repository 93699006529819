import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
  QueryOptions,
} from '@apollo/client';
import fetch from 'isomorphic-unfetch';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const uri = process.env.CMS_GRAPHQL_URL;

const httpLink = createHttpLink({
  uri,
  fetch,
});

export const cmsApiClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  defaultOptions,
  credentials: 'include',
});

export const getLocales = async () =>
  await fetch(uri.replace('/graphql', '/i18n/locales')).then(data =>
    data.json()
  );

export const queryCMS = async <ResponseData = any>(
  params: QueryOptions<ResponseData>
): Promise<ResponseData> => {
  const response = await cmsApiClient.query(params);
  if (response.errors && response.errors.length > 0)
    throw new Error(`CMS Query Error: ${response.errors[0].message}`);
  return JSON.parse(JSON.stringify(response));
};
